import React from 'react';

import { useNavigate } from 'react-router-dom';

import { format } from 'date-fns';

import { Skeleton } from 'primereact/skeleton';

import { IPost } from 'types/post';

import './card.scss';

interface IProps {
  post: IPost;
}

const Card: React.FC<IProps> = ({ post }) => {
  const history = useNavigate();

  const [imageLoaded, setImageLoaded] = React.useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  function removeTags(text: string) {
    const match: RegExpMatchArray | null = text.match(/<p[^>]*>(.*?)<\/p>/);

    if (match) {
      return match[1].replace(/<[^>]*>/g, '');
    }

    return text;
  }

  const ComponentCard = () => {
    return (
      <div className='posts-content' onClick={() => history(`/blog/${post.url}`)}>
        <div className='image-container'>
          {!imageLoaded && <Skeleton width='100%' height='300px' />}
          <img
            className='image'
            src={`https://firebasestorage.googleapis.com/v0/b/erp-epsys.appspot.com/o/blog-images%2F${post.image}?alt=media`}
            onLoad={handleImageLoad}
            style={{ display: !imageLoaded ? 'none' : 'flex' }}
          />
        </div>
        <div className='description-container'>
          <div className='line-clamp'>
            <div className='title'>{post.title}</div>
            <div className='date'>
              Por {post.author}, {format(post.createdAt.toDate(), 'dd/LL/yyyy')}
            </div>
            <div className='description'>{removeTags(post.article)}</div>
          </div>
        </div>
      </div>
    );
  };

  return <ComponentCard />;
};

export default Card;
