import React from 'react';
import imageMap from 'assets/images/background-map.png';

import { BasicButton } from 'components/buttons/basicButton/basicButton';

import './leadMap.scss';

const LeadMap: React.FC = () => {
  return (
    <div id='lead-map' className='container-map reveal'>
      <div className='container-image'>
        <div className='image-container-emphasis'>
          <img className='image' src={imageMap} />
        </div>
      </div>
      <div className='container-text'>
        <div className='title'>Mais de 200.000 Estudantes com o nosso programa!</div>
        <div className='subtitle'>
          Conheça melhor nossos indicadores de qualidade e tenha certeza que somos a escolha certa para que você
          conquiste o seu sucesso profissional.
        </div>
        <BasicButton
          label='ENTRAR EM CONTATO'
          severity='info'
          customClassName='custom-button-color'
          style={{ padding: '1.5rem 3.5rem' }}
          onClick={() => {
            window.open(
              window.innerWidth < 1200
                ? 'https://api.whatsapp.com/send?phone=5577999136775&text=Ol%C3%A1! Vim pelo site e tenho algumas dúvidas. Gostaria de obter mais informações. Você pode me ajudar com isso?'
                : 'https://web.whatsapp.com/send?phone=5577999136775&amp;text=Olá! Vim pelo site e tenho algumas dúvidas. Gostaria de obter mais informações. Você pode me ajudar com isso?'
            );
          }}
        />
      </div>
    </div>
  );
};

export default LeadMap;
