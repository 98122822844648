import { collection, doc, getDoc, Timestamp, writeBatch } from 'firebase/firestore';

import { firebaseFirestore } from 'services/firebase/authentication';

import { IMail } from 'types/mail';

interface IOptions {
  count: number;
  id: string;
}

export const sendMail = async (mail: Partial<IMail>) => {
  delete mail.id;
  const newMail = {
    ...mail,
    createdAt: Timestamp.now(),
  };

  try {
    const docRef = doc(firebaseFirestore, 'ALL-code3c', '010500005', 'BWS-shard', 'email');
    const docSnap = await getDoc(docRef);

    const count = { id: docSnap.id, ...docSnap.data() } as IOptions;

    const batch = writeBatch(firebaseFirestore);

    batch.update(docRef, {
      count: count.count + 1,
    } as Partial<IOptions>);

    const collectionRef = collection(firebaseFirestore, 'ALL-mail');

    const docRef2 = doc(collectionRef);

    batch.set(docRef2, {
      ...newMail,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
    });

    await batch.commit();

    return true;
  } catch (error) {
    console.log('Erro ao estabelecer conexão com o banco de dados', error);

    return false;
  }
};

export const deleteContact = async (id: string) => {
  try {
    const docRef = doc(firebaseFirestore, 'ALL-code3c', '010500005', 'BWS-shard', 'email');
    const docSnap = await getDoc(docRef);

    const count = { id: docSnap.id, ...docSnap.data() } as IOptions;
    const batch = writeBatch(firebaseFirestore);

    batch.update(docRef, {
      count: count.count - 1,
    } as Partial<IOptions>);

    const laRef = doc(firebaseFirestore, 'ALL-mail', id);
    batch.delete(laRef);
    await batch.commit();

    return true;
  } catch (error) {
    console.error('Erro ao estabelecer conexão com o banco de dados');
    return false;
  }
};
