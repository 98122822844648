import React from 'react';
import logoOnly from 'assets/images/logo.png';

import { ProgressSpinner } from 'primereact/progressspinner';

import './authLoading.scss';

interface AuthLoadingProps {
  fullscreen?: boolean;
}

export const AuthLoading: React.FC<AuthLoadingProps> = ({ fullscreen }) => {
  return (
    <div className={`auth-loading ${fullscreen ? 'auth-loading-fullscreen' : ''}`}>
      <div className='auth-loading-logo'>
        <img src={logoOnly} className='growing-animation' />
      </div>
      <ProgressSpinner
        className='auth-loading-spinner'
        strokeWidth='4'
        animationDuration='2s'
        fill='var(--surface-ground)'
      />
    </div>
  );
};
