import * as React from 'react';

import './card.scss';

export interface BasicCardProps {
  title: string;
  description: string;
  image: string;
  logo: string;
}

const Card: React.FC<BasicCardProps> = ({ title, description, image, logo }) => {
  return (
    <div className='card-courses'>
      <div className='card-courses-content'>
        <div className='card-courses-image'>
          <img src={image} />
        </div>
        <div className='card-courses-decriptions'>
          <div className='card-courses-description-title' title={title}>
            <p className='title'>{title}</p>
          </div>
          <div className='card-courses-description'>{description}</div>
          <a
            className='qlwapp-toggle card-courses-description-link'
            data-action='open'
            data-phone='5577999136775'
            data-message={`Ola me interessei pelo curso ${title} e gostaria de ter mais informações, poderia me ajudar ?`}
            role='button'
            target='_blank'
            rel='noreferrer'
            href={`https://api.whatsapp.com/send?phone=5577999136775&text=Ol%C3%A1, me interessei pelo curso ${title} e gostaria de ter mais informações, poderia me ajudar ?`}
          >
            Entrar em contato
          </a>
          {logo && (
            <img
              className='logo-card'
              src={logo}
              alt='Curso em parceria com a MUST University'
              title='Curso em parceria com a MUST University'
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
