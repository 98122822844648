import * as React from 'react';

import { courses } from '../data/courses';

import List from './list/list';

import './card.scss';

export interface BasicCardProps {
  title: string;
  description: string;
  image: JSX.Element;
  sequence: string;
  index: number;
}

const Course: React.FC<BasicCardProps> = ({ title, description, image, sequence, index }) => {
  return (
    <div className='card-course'>
      <div className='card-course-content'>
        <div className='card-course-content-text'>
          <div className={'card-course-image ' + sequence}>{image}</div>
          <div className='card-course-decriptions'>
            <div className='card-course-description-title'>{title}</div>
            <div className='card-course-description'>{description}</div>
            <a
              className='qlwapp-toggle card-course-description-link'
              data-action='open'
              data-phone='5577999136775'
              data-message={`Ola me interessei em cursos de ${title} e gostaria de ter mais informações, poderia me ajudar ?`}
              role='button'
              target='_blank'
              rel='noreferrer'
              href={`https://api.whatsapp.com/send?phone=5577999136775&text=Ol%C3%A1, me interessei em cursos de ${title} e gostaria de ter mais informações, poderia me ajudar ?`}
            >
              Entrar em contato
            </a>
          </div>
        </div>
        <List key={courses[index].title + index} cards={courses[index].cards} />
      </div>
    </div>
  );
};

export default Course;
