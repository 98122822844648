import React from 'react';

import { Skeleton } from 'primereact/skeleton';

import { IPost } from 'types/post';

import './recentPosts.scss';

interface IProps {
  item: IPost;
}

const RecentPosts: React.FC<IProps> = ({ item }) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <a href={`/blog/${item.url}`} className='no-decoration'>
      <div className='posts-content-recent'>
        <div className='image-container'>
          {!imageLoaded && <Skeleton width='100%' height='180px' />}
          <img
            className='image'
            src={`https://firebasestorage.googleapis.com/v0/b/erp-epsys.appspot.com/o/blog-images%2F${item.image}?alt=media`}
            onLoad={handleImageLoad}
            style={{ display: !imageLoaded ? 'none' : 'flex' }}
          />
        </div>
        <div className='description-container'>
          <div className='title'>{item.title}</div>
        </div>
      </div>
    </a>
  );
};

export default RecentPosts;
