import React from 'react';
import ReactPixel from 'react-facebook-pixel';

// import ReactGA from 'react-ga4';
import ReactDOM from 'react-dom/client';

import reportWebVitals from 'services/reportWebVitals';

import { MainRoute } from 'routes/main.routes';

import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import './index.scss';

const options = {
  autoConfig: true,
  debug: true,
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <MainRoute />
  </React.StrictMode>
);

// ReactGA.initialize('AW-11336078938');

ReactPixel.init('1600389457031494', undefined, options);

reportWebVitals();
