import React from 'react';

import { format } from 'date-fns';

import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';

import { Auth } from 'contexts/auth.context';

import { BasicButton } from 'components/buttons/basicButton/basicButton';
import { BasicInput } from 'components/inputs/basicInput/basicInput';

import { YupTypesError } from 'types/yupTypes';

import './header.scss';

interface IProps {
  viewId: string | undefined;
  id: string | undefined;
  preview: boolean;
  setPreview: React.Dispatch<React.SetStateAction<boolean>>;
  handleAdd: (type: string) => void;
  handleEdit: (type: string) => void;
  title: string;
  author: string;
  date: Date;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setUrl: React.Dispatch<React.SetStateAction<string>>;
  validationErrors: YupTypesError[];
  functionDelete: () => void;
}

const Header: React.FC<IProps> = ({
  viewId,
  id,
  preview,
  setPreview,
  handleAdd,
  title,
  author,
  date,
  setTitle,
  setUrl,
  handleEdit,
  validationErrors,
  functionDelete,
}) => {
  const { user } = React.useContext(Auth);

  const handleTitle = (value: string) => {
    const url = value
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');

    setTitle(value);
    setUrl(url);
  };

  const confirmAddEdit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    confirmPopup({
      target: event.currentTarget,
      message: 'Voce tem certeza que deseja publicar?',
      icon: 'pi pi-check',
      acceptClassName: id ? 'confirm-accept' : 'confirm-edit',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: () => (id ? handleEdit('edit') : handleAdd('add')),
    });
  };

  const confirmDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    confirmPopup({
      target: event.currentTarget,
      message: 'Voce tem certeza que deseja deletar?',
      icon: 'pi pi-exclamation-triangle',
      acceptClassName: 'confirm-reject',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: () => functionDelete(),
    });
  };

  return (
    <div className='post-header'>
      {!viewId && (
        <div className='container-header'>
          <ConfirmPopup />
          <div className='content-title'>{id ? 'EDITAR POST' : 'NOVO POST'}</div>
          <div className='content-button'>
            <BasicButton
              customClassName='preview'
              label={preview ? 'Esconder Preview' : 'Mostrar Preview'}
              onClick={() => setPreview((state) => !state)}
              iconName={!preview ? 'FaEye' : 'FaEyeSlash'}
            />
            {id && <BasicButton customClassName='cancel' label='Deletar' iconName='FaTimes' onClick={confirmDelete} />}
            <BasicButton
              customClassName={id ? 'edit' : 'save'}
              label='Publicar'
              iconName='FaUpload'
              onClick={confirmAddEdit}
            />
          </div>
        </div>
      )}

      {viewId || preview ? (
        <div className='title'>{title}</div>
      ) : (
        <BasicInput
          customClassName='input-title'
          placeholder='Titulo'
          type='text'
          iconName='FaT'
          iconPosition='LEFT'
          inputId='title'
          validationErrors={validationErrors}
          onChange={(e) => handleTitle(e.target.value)}
          value={title}
          disabled={!!id}
        />
      )}

      <div className='date'>
        {id || viewId ? <span>por {author}</span> : <span>por {user?.name}</span>}, {format(date, 'dd/LL/yyyy')}
      </div>
    </div>
  );
};

export default Header;
