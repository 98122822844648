import React from 'react';

import './commentsFacebook.scss';

interface IProps {
  url: string;
}

const CommentsFacebook: React.FC<IProps> = ({ url }) => {
  React.useEffect(() => {
    const response: any = window;

    if (response.FB) {
      response.FB.XFBML.parse();
    }
  }, []);

  return (
    <div className='comments'>
      <div className='title-comments'>Deixe seu Comentário:</div>
      <div
        className='fb-comments'
        data-href={url ? `https://froesecalazans.com.br/${url}` : 'https://froesecalazans.com.br/'}
        data-width='100%'
        data-numposts='5'
      />
    </div>
  );
};

export default CommentsFacebook;
