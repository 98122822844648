import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

export const firebaseConnect = initializeApp({
  apiKey: process.env.REACT_APP_PSH_BWS_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_PSH_BWS_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_PSH_BWS_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PSH_BWS_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PSH_BWS_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_PSH_BWS_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_PSH_BWS_FIREBASE_MEASUREMENT_ID,
});

export const firebaseFirestore = getFirestore(firebaseConnect);

if (process.env.NODE_ENV === 'production') {
  getAnalytics(firebaseConnect);
}
