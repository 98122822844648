import * as React from 'react';

import Navigator from 'components/navigator/navigator';

export type NavigatorMode = 'ABSOLUTE' | 'RELATIVE';

interface NavigatorProviderProps {
  children: React.ReactNode;
}

interface INavigatorContext {
  navigatorMode: NavigatorMode;
  setterNavigator: (navigatorMode: NavigatorMode) => void;
  enabledHeader: boolean;
  setterEnableHeader: (boolean: boolean) => void;
  enabledBackToTop: boolean;
  setterBackToTop: (boolean: boolean) => void;
  enabledWidget: boolean;
  setterEnableWidget: (boolean: boolean) => void;
  enabledFooter: boolean;
  setterEnableFooter: (boolean: boolean) => void;
  enabledAdminNav: boolean;
  setterEnableAdminNav: (boolean: boolean) => void;
  enabledControlPannel: boolean;
  setterEnableControlPannel: (boolean: boolean) => void;
  screenSize: number;
}

export const NavigatorContext = React.createContext<INavigatorContext>({} as INavigatorContext);

export const NavigatorProvider: React.FC<NavigatorProviderProps> = ({ children }) => {
  const [navigatorMode, setNavigatorMode] = React.useState<NavigatorMode>('ABSOLUTE');
  const [enabledHeader, setEnabledHeader] = React.useState<boolean>(true);
  const [enabledControlPannel, setEnabledControlPannel] = React.useState<boolean>(false);
  const [enabledBackToTop, setEnabledBackToTop] = React.useState<boolean>(true);
  const [enabledWidget, setEnabledWidget] = React.useState<boolean>(true);
  const [enabledFooter, setEnabledFooter] = React.useState<boolean>(true);

  const [enabledAdminNav, setEnabledAdminNav] = React.useState<boolean>(false);

  const setterNavigator = (navigatorMode: NavigatorMode) => {
    setNavigatorMode(navigatorMode);
  };

  const setterEnableHeader = (enableHeader: boolean) => {
    setEnabledHeader(enableHeader);
  };

  const setterEnableControlPannel = (enableControlPannel: boolean) => {
    setEnabledControlPannel(enableControlPannel);
  };

  const setterBackToTop = (enableBackToTop: boolean) => {
    setEnabledBackToTop(enableBackToTop);
  };

  const setterEnableWidget = (enableWidget: boolean) => {
    setEnabledWidget(enableWidget);
  };

  const setterEnableFooter = (enableFooter: boolean) => {
    setEnabledFooter(enableFooter);
  };

  const setterEnableAdminNav = (enableAdminNav: boolean) => {
    setEnabledWidget(!enableAdminNav);
    setterEnableFooter(!enableAdminNav);
    setEnabledAdminNav(enableAdminNav);
  };

  const [screenSize, setScreenSize] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const updateDimension = () => {
      setScreenSize(window.innerWidth);
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  return (
    <NavigatorContext.Provider
      value={{
        navigatorMode,
        setterNavigator,
        enabledHeader,
        setterEnableHeader,
        enabledControlPannel,
        setterEnableControlPannel,
        enabledBackToTop,
        setterBackToTop,
        enabledWidget,
        setterEnableWidget,
        enabledFooter,
        setterEnableFooter,
        enabledAdminNav,
        setterEnableAdminNav,
        screenSize,
      }}
    >
      <Navigator>{children}</Navigator>
    </NavigatorContext.Provider>
  );
};
