import React from 'react';

import { BasicButton } from 'components/buttons/basicButton/basicButton';
import { BasicInput } from 'components/inputs/basicInput/basicInput';

import './listSearch.scss';

interface IProps {
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
}

const ListSearch: React.FC<IProps> = ({ setInputValue }) => {
  const [internalInputValue, setInternalInputValue] = React.useState('');

  return (
    <div className='list-search'>
      <BasicInput
        value={internalInputValue}
        placeholder='Pesquisar'
        type='text'
        iconName='FaMagnifyingGlass'
        iconPosition='LEFT'
        customClassName='search-input'
        onChange={(e) => setInternalInputValue(e.target.value)}
      />
      <BasicButton
        className='button-search'
        label='Pesquisar'
        onClick={() => setInputValue(internalInputValue)}
        style={{ width: '10rem' }}
      />
    </div>
  );
};

export default ListSearch;
