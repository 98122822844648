import * as React from 'react';
import { collection, limit, onSnapshot, query, where } from 'firebase/firestore';

import { firebaseFirestore } from 'services/firebase/authentication';

import { IPost } from 'types/post';

interface PostsHook {
  similarPost: IPost[];
  pendingSimilarPost: boolean;
}

export function useSimilarPosts(tags: string[]): PostsHook {
  const [similarPost, setSimilarPost] = React.useState<IPost[]>([]);
  const [pendingSimilarPost, setPendingSimilarPost] = React.useState(true);

  React.useEffect(() => {
    setPendingSimilarPost(true);

    const unsubscribe = onSnapshot(
      query(
        collection(firebaseFirestore, 'ALL-code3c', '010500005', 'BWS-posts'),
        limit(7),
        where('tags', 'array-contains-any', tags.length !== 0 ? tags : [''])
      ),
      (snapshot) => {
        const list: IPost[] = [];

        if (snapshot.empty) {
          setPendingSimilarPost(false);
          return;
        }

        snapshot.docs.forEach((item) => {
          list.push({ ...item.data(), id: item.id } as IPost);
        });

        setSimilarPost(list);
        setPendingSimilarPost(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [tags]);

  return { similarPost, pendingSimilarPost };
}
