import React from 'react';

import { InputText, InputTextProps } from 'primereact/inputtext';

import BasicIcon from 'components/icon/basicIcon/basicIcon';

import { YupTypesError } from 'types/yupTypes';

import { BasicInputErrors } from '../basicInputErrors/basicInputErrors';

import './basicInput.scss';

type IconPosition = 'LEFT' | 'RIGHT';

export interface BasicInputProps extends InputTextProps {
  iconName?: string;
  iconPosition?: IconPosition;
  iconFunction?: () => void;
  inputId?: string;
  mandatory?: boolean;
  label?: string;
  type?: 'email' | 'hidden' | 'number' | 'password' | 'text' | 'url';
  disabled?: boolean;
  customClassName?: string;
  floatLabel?: boolean;
  validationErrors?: YupTypesError[];
}
export const BasicInput: React.FC<BasicInputProps> = (props) => {
  const {
    iconName,
    iconPosition,
    iconFunction,
    inputId,
    mandatory,
    label,
    type,
    disabled,
    customClassName,
    floatLabel,
    validationErrors,
    ...inputProps
  } = props;

  return (
    <div className={`basic-input ${customClassName ? customClassName + ' ' + props.className : props.className}`}>
      <span
        className={`${floatLabel ? 'p- float-label' : ''} ${
          iconPosition ? 'p-input-icon-' + iconPosition.toLowerCase() : ''
        }`}
      >
        {iconName && (
          <BasicIcon
            iconName={iconName}
            className={`icon ${iconFunction ? 'cursor' : ''}`}
            onClick={() => {
              iconFunction && iconFunction();
            }}
          />
        )}
        <InputText
          id={inputId}
          name={inputId}
          className={`field`}
          disabled={disabled}
          type={type}
          autoCorrect='off'
          spellCheck='false'
          autoComplete='off'
          {...inputProps}
        />

        <label htmlFor={inputId}>{(label || '') + (mandatory ? ' *' : '')}</label>
      </span>
      {inputId && validationErrors && <BasicInputErrors inputId={inputId} validationErrors={validationErrors} />}
    </div>
  );
};
