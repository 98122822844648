import * as React from 'react';

import { Avatar, AvatarProps } from 'primereact/avatar';

import 'styles/buttons.scss';
import './basicAvatar.scss';

interface BasicButtonProps extends AvatarProps {
  customClassName?: string;
}

export const BasicAvatar = (props: BasicButtonProps) => {
  const { customClassName, ...avatarProps } = props;

  return (
    <Avatar
      className={`basic-avatar ${customClassName ? customClassName + ' ' + props.className : props.className}`}
      {...avatarProps}
    />
  );
};
