import React from 'react';

import { Skeleton } from 'primereact/skeleton';

import './skeleton.scss';

const SkeletonCard: React.FC = () => {
  const ComponentSkeleton = () => {
    return (
      <div className='skeleton-container'>
        <Skeleton className='emphasis' />
        <div className='container-card'>
          {[1, 2, 3, 4, 5, 6].map((item, index) => {
            return <Skeleton key={index} className='card' />;
          })}
        </div>
      </div>
    );
  };

  return <ComponentSkeleton />;
};

export default SkeletonCard;
