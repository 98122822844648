import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';

import { NavigatorContext } from 'contexts/navigator.context';

import { BasicButton } from 'components/buttons/basicButton/basicButton';

import logoOnly from '../../../assets/images/logo.png';

import Card from './components/card';
import Emphasis from './components/emphasis';
import ListSearch from './components/listSearch';
import Skeleton from './components/skeleton';
import { useSearchOptions } from './hooks/useOptions';
import { usePosts } from './hooks/usePosts';

import './listing.scss';

// Componente Responsável por renderizar a listagem do blog e gerenciar seu estado interno
const Blog: React.FC = () => {
  // Importando contextos necessários
  const { setterNavigator, setterEnableAdminNav, setterEnableControlPannel, screenSize } =
    React.useContext(NavigatorContext);

  // Definindo estados iniciais
  const [inputValue, setInputValue] = React.useState('');

  // Importando hooks necessários
  const { posts, pendingPosts, loadMore } = usePosts(inputValue);
  const { options, pendingOptions } = useSearchOptions();

  // Componente para exibir o botão "Carregar mais posts"
  const ComponentMorePosts = () => {
    if (posts.length !== options?.count && posts.length > 0 && inputValue === '') {
      return (
        <BasicButton
          className='button-load-more'
          label='Carregar mais'
          onClick={() => loadMore()}
          disabled={pendingPosts}
        />
      );
    }
  };

  const newPostList = React.useMemo(() => {
    if (screenSize > 1250) {
      return posts.filter((item, index) => index > 0);
    } else {
      return posts;
    }
  }, [posts, screenSize]);

  //  Hook `useEffect` utilizado para setar os valores de navegação.
  React.useEffect(() => {
    setterNavigator('RELATIVE');
    setterEnableAdminNav(false);
    setterEnableControlPannel(false);
  }, [setterEnableAdminNav, setterEnableControlPannel, setterNavigator]);

  React.useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/blog' });
    ReactPixel.pageView();
  }, []);

  return (
    <div className='blog-listing-container'>
      <div className='content'>
        <div className='page-title'>Blog</div>
        <ListSearch setInputValue={setInputValue} />
        {pendingPosts || pendingOptions ? (
          <Skeleton />
        ) : (
          <React.Fragment>
            {posts.length === 0 ? (
              <div className='no-posts-container'>
                <div className='no-posts'>
                  <img src={logoOnly} />
                  <p>Nenhuma postagem</p>
                </div>
              </div>
            ) : (
              <React.Fragment>
                <Emphasis posts={posts} />
                <div className='posts-container'>
                  {newPostList?.map((item, index) => {
                    return <Card key={index} item={item} />;
                  })}
                </div>
                <ComponentMorePosts />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default Blog;
