import * as React from 'react';
import contact from 'assets/images/contact.png';
import { sendMail } from 'controller/contact.controller';

import { BasicButton } from 'components/buttons/basicButton/basicButton';
import { BasicInput } from 'components/inputs/basicInput/basicInput';
import { BasicSwitch } from 'components/inputs/basicSwitch/basicSwitch';

import { YupTypesError } from 'types/yupTypes';

import { ContactSchema, contactSchema } from './contact.validations';
import { emailTemplate } from './email';

import './contact.scss';

interface ContactProps {
  onModal?: boolean;
}

const Contact: React.FC<ContactProps> = ({ onModal }) => {
  const [name, setName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [phone, setPhone] = React.useState<string>('');
  const [course, setCourse] = React.useState<string>('');

  const [acceptedNews, setAcceptedNews] = React.useState<boolean>(false);

  const [loading, setLoading] = React.useState(false);

  const [sended, setSended] = React.useState(false);
  const [error, setError] = React.useState(false);

  const [validationErrors, setValidationErrors] = React.useState<YupTypesError[]>([]);

  const handleSendEmail = (): void => {
    setLoading(true);

    const contactValidation: ContactSchema = {
      name: name,
      email: email,
      phone: phone,
      course: course,
      acceptedNews: acceptedNews,
    };

    contactSchema
      .validate(contactValidation, { abortEarly: false })
      .then(async () => {
        const emailParams = {
          to: ['contato@epsys.io'],
          code3c_id: '010500005',
          message: {
            subject: 'Nova mensagem do site Instituto Pinheiro',
            text: '',
            html: emailTemplate(name, email, phone, acceptedNews, course),
          },
          contact: {
            name: name,
            email: email,
            phone: phone,
            course: course,
            acceptedNews: acceptedNews,
          },
        };
        const result = await sendMail(emailParams);
        if (result) {
          setName('');
          setEmail('');
          setPhone('');
          setAcceptedNews(false);
          setLoading(false);
          setSended(true);
          if (error) {
            setError(false);
          }
          setTimeout(() => {
            setSended(false);
          }, 3000);
        } else {
          setError(true);
        }
      })
      .catch((err: YupTypesError) => {
        if (err) {
          setValidationErrors(err.inner);
          console.log(err.inner);
        } else {
          setValidationErrors([]);
        }
        setError(true);
        setLoading(false);
      });
  };

  return (
    <div id='contato' className={`container no-posts ${onModal && 'on-modal'}`}>
      <div className={`content ${!onModal && 'reveal'}`}>
        <div className={`contact-content ${onModal && 'on-modal'}`}>
          <div className={`contacts-image ${onModal && 'on-modal'}`}>
            <img src={contact} />
          </div>
          <div className={`contact-left ${onModal && 'on-modal'}`}>
            <div className='title'>{onModal ? 'NÃO VÁ AINDA!' : 'ENTRE EM CONTATO'}</div>
            <div className='description'>
              {onModal
                ? 'Restou alguma dúvida? Entre em contato conosco para melhor atende-lo(a).'
                : 'Está com alguma dúvida? entre em contato conosco para melhor atende-lo(a).'}
            </div>
            <div className='contact-form'>
              <BasicInput
                placeholder='Nome'
                type='text'
                iconName='FaUser'
                iconPosition='LEFT'
                value={name}
                onChange={(e) => setName(e.target.value)}
                inputId='name'
                validationErrors={validationErrors}
              />
              <BasicInput
                placeholder='Telefone'
                type='text'
                iconName='FaPhone'
                iconPosition='LEFT'
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                inputId='phone'
                validationErrors={validationErrors}
              />
              <BasicInput
                placeholder='E-mail'
                type='email'
                iconName='FaEnvelope'
                iconPosition='LEFT'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                inputId='email'
                validationErrors={validationErrors}
              />
              <BasicInput
                placeholder='Curso desejado'
                type='text'
                iconName='FaBook'
                iconPosition='LEFT'
                value={course}
                onChange={(e) => setCourse(e.target.value)}
                inputId='courese'
                validationErrors={validationErrors}
              />
              <BasicSwitch
                inputId='news'
                checked={acceptedNews}
                onChange={() => setAcceptedNews(!acceptedNews)}
                label='Quero receber e-mails com notícias de novos cursos ou promoções.'
              />
              {sended && <span className='contact-success-message'>Mensagem enviada com sucesso!</span>}
              {error && (
                <span className='contact-error-message'>
                  Ocorreu um erro ao tentar enviar sua mensagem por favor verifique os campos tente novamente!
                </span>
              )}
              <BasicButton
                label='Enviar'
                severity='info'
                customClassName='custom-button-color'
                onClick={handleSendEmail}
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
