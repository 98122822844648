import React from 'react';
import ReactGA from 'react-ga4';

import { FaWhatsapp } from 'react-icons/fa';

import './contactWidget.scss';

export const reportConversion = () => {
  ReactGA.event({
    category: 'Contact',
    action: 'conversion',
    label: 'Whatsapp', // optional
  });
};

const ContactWidget: React.FC = () => {
  return (
    <div className='contact-widget'>
      {window.innerWidth < 1200 ? (
        <a
          className='qlwapp-toggle'
          data-action='open'
          data-phone='557799894343'
          data-message='Olá,preciso de assessoria especializada para resolver um problema ou para prevenir que algum aconteça.'
          role='button'
          target='_blank'
          rel='noreferrer'
          href='https://api.whatsapp.com/send?phone=557799894343&text=Ol%C3%A1,
          preciso de assessoria especializada para resolver um problema ou para prevenir que algum aconteça.'
          onClick={reportConversion}
        >
          <div className='contact-widget-message'>Fale com um especialista!</div>
          <div className='contact-widget-button'>
            <FaWhatsapp />
          </div>
        </a>
      ) : (
        <a
          className='qlwapp-toggle'
          data-action='open'
          data-phone='557799894343'
          data-message='Olá,preciso de assessoria especializada para resolver um problema ou para prevenir que algum aconteça.'
          role='button'
          target='_blank'
          rel='noreferrer'
          href='https://web.whatsapp.com/send?phone=557799894343&amp;text=Ola,
          preciso de assessoria especializada para resolver um problema ou para prevenir que algum aconteça.'
          onClick={reportConversion}
        >
          <div className='contact-widget-message'>Fale com um especialista!</div>
          <div className='contact-widget-button'>
            <FaWhatsapp />
          </div>
        </a>
      )}
    </div>
  );
};

export default ContactWidget;
