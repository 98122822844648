import * as yup from 'yup';
import { pt as portuguese } from 'yup-locale-pt';

yup.setLocale(portuguese);

interface ContactSchema {
  name: string;
  email: string;
  phone: string;
  course: string;
  acceptedNews: boolean;
}

const contactSchema = yup.object().shape({
  email: yup.string().email().label('Email').required('Você precisa inserir um email'),
  name: yup.string().label('Nome').required('Você precisa inserir um nome'),
  phone: yup.string().label('Telefone').required('Você precisa inserir um telefone'),
  course: yup.string().label('Curso').required('Você precisa inserir um curso que deseja'),
});

const newsletterSchema = yup.object().shape({
  email: yup.string().email().label('Email').required('Você precisa inserir um email'),
});

export { type ContactSchema, contactSchema, newsletterSchema };
