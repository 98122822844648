import * as yup from 'yup';
import { pt } from 'yup-locale-pt';

yup.setLocale(pt);

export const dataSchema = yup.object().shape({
  title: yup.string().label('Titulo').required(),
  tags: yup.array(yup.string().required()).min(1),
  image: yup.string().label('Imagem').required(),
  article: yup.string().label('Artigo').required(),
});

export const dataSchemaNotImage = yup.object().shape({
  title: yup.string().label('Titulo').required(),
  tags: yup.array(yup.string().required()).min(1),
  article: yup.string().label('Artigo').required(),
});
