import React from 'react';

import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';

import { Auth } from 'contexts/auth.context';

import { BasicAvatar } from 'components/avatar/basicAvatar';
import BasicIcon from 'components/icon/basicIcon/basicIcon';

import './userHeaderMenu.scss';

const UserHeaderMenu: React.FC = () => {
  const { user, clearAuthorization } = React.useContext(Auth);

  const menu = React.useRef<Menu>(null);

  const items: MenuItem[] = [
    {
      label: 'Sair',
      icon: <BasicIcon iconName='FaArrowRightToBracket' />,
      className: 'menu-button-template',
      command: () => clearAuthorization && clearAuthorization(),
    },
  ];

  if (!user) {
    return <React.Fragment />;
  }

  return (
    <div className='user-header-menu'>
      <React.Fragment>
        {user?.name}
        <div>
          <BasicAvatar
            customClassName='user-header-menu-avatar'
            image={user?.profileImg}
            onClick={(e) => menu.current?.toggle(e)}
          >
            {user?.profileImg === '' && <BasicIcon iconName='FaRegUser' />}
          </BasicAvatar>
          <Menu ref={menu} model={items} popup className='user-header-menu-drop-menu' />
        </div>
      </React.Fragment>
    </div>
  );
};

export default UserHeaderMenu;
