import React from 'react';

import { BasicButton } from 'components/buttons/basicButton/basicButton';

import { IPost } from 'types/post';

import Card from './components/card';

import './posts.scss';

interface IProps {
  posts: IPost[];
}
const Posts: React.FC<IProps> = ({ posts }) => {
  return (
    <div id='posts' className='container'>
      <div className='content reveal'>
        <div className='title-posts'>Blog</div>
        <div className='posts-container'>
          {posts.map((post, index) => {
            return <Card key={index} post={post} />;
          })}
        </div>
        <a className='no-decoration' href='/blog'>
          <BasicButton className='button-load-more' label='Confira mais conteúdos' />
        </a>
      </div>
    </div>
  );
};

export default Posts;
