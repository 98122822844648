import * as React from 'react';

import { SectionBar } from 'components/bars/sectionbar/sectionBar';
import { BasicButton } from 'components/buttons/basicButton/basicButton';
import YoutubeEmbed from 'components/embed/youtubeEmbed/youtubeEmbed';

import './aboutUs.scss';

const AboutUs: React.FC = () => {
  return (
    <div id='sobre-nos' className='container'>
      <div className='content reveal'>
        <div className='descriptions'>
          <div className='office-description'>
            <div className='title'>Sobre Nós</div>
            <p className='office-description-emphasis'>
              Conheça um pouco da nossa história e se surpreenda ainda mais com o institulo pinheiro
            </p>
            <br />
            <p>
              No Oeste da Bahia na cidade de Barreiras -Bahia, o Instituto Pinheiro-Assessoria Educacional e Sistema de
              Informação Ltda., é um bastião de aprendizado e inovação.Fundado em 05/01/2009, o Instituto Pinheiro, tem
              como objetivo, a prestação de serviços educacionais de qualidade. Ele representa uma sinergia única entre
              educação e tecnologia, onde a missão é capacitar indivíduos para prosperar em um mundo cada vez mais
              complexo e digitalizado.A essência do instituto reside na prestação de serviços Educacionais com parcerias
              com instituições de Ensino superior nacionais e internacionais ofertando cursos Técnico, de graduação, pós
              Lato sensu e stricto sensu.
            </p>
            <br />
            <div className='office-description-emphasis-video'>
              <YoutubeEmbed embedLinkId='Da-nc_I4_Wk' />
            </div>
            <br />
            <p>
              Assim, a história do Instituto Pinheiro-Assessoria Educacional e Sistema de Informação Ltda. é uma
              narrativa de visão, dedicação e transformação, onde educação e tecnologia se entrelaçam para criar um
              ambiente de aprendizado inspirador e capacitador.
            </p>

            <BasicButton
              label='ENTRAR EM CONTATO'
              severity='secondary'
              customClassName='about-us-button'
              onClick={() => {
                window.open(
                  window.innerWidth < 1200
                    ? 'https://api.whatsapp.com/send?phone=5577999136775&text=Ol%C3%A1! Vim pelo site e tenho algumas dúvidas. Gostaria de obter mais informações. Você pode me ajudar com isso?'
                    : 'https://web.whatsapp.com/send?phone=5577999136775&amp;text=Olá! Vim pelo site e tenho algumas dúvidas. Gostaria de obter mais informações. Você pode me ajudar com isso?'
                );
              }}
            />
            <br />
            <YoutubeEmbed embedLinkId='_s0-gIL3ZOI' />
          </div>
        </div>
      </div>
      <SectionBar component='aboutUs' />
    </div>
  );
};

export default AboutUs;
