import React from 'react';
import parse from 'html-react-parser';

import { useParams } from 'react-router-dom';

import { Editor, EditorTextChangeEvent } from 'primereact/editor';

import { BasicInputErrors } from 'components/inputs/basicInputErrors/basicInputErrors';
import { reportConversion } from 'components/navigator/components/contactWidget/contactWidget';

import { headerQuill } from 'utils/blog';

import { YupTypesError } from 'types/yupTypes';

import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import './editorQuill.scss';

interface IProps {
  viewId: string | undefined;
  preview: boolean;
  article: string;
  setArticle: React.Dispatch<React.SetStateAction<string>>;
  validationErrors: YupTypesError[];
}

const EditorQuill: React.FC<IProps> = ({ viewId, preview, article, setArticle, validationErrors }) => {
  const { id } = useParams();

  const setText = (text: EditorTextChangeEvent) => {
    if (text && text.source !== 'api') {
      setArticle(text.htmlValue as string);
    }
  };

  return (
    <React.Fragment>
      <div className='editor-article'>
        {viewId || preview ? (
          <div className='ql-editor editor'>{parse(article)}</div>
        ) : (
          <React.Fragment>
            {(article !== '' || (article === '' && !id)) && (
              <Editor
                headerTemplate={headerQuill()}
                value={article}
                onTextChange={(e) => setText(e)}
                placeholder='Artigo'
                className='quill-editor'
              />
            )}
            <div> {validationErrors && <BasicInputErrors inputId='article' validationErrors={validationErrors} />}</div>
          </React.Fragment>
        )}
      </div>
      {viewId && (
        <div className='speak-expert-container'>
          <div className='title'>Ainda com dúvidas? Entre em contato conosco:</div>
          <a
            className='qlwapp-toggle no-decoration'
            data-action='open'
            data-phone='557781664357'
            data-message='Olá, vim pelo site e tenho algumas dúvidas, poderia me ajudar ?'
            role='button'
            target='_blank'
            rel='noreferrer'
            href='https://web.whatsapp.com/send?phone=557781664357&amp;text=Ol%C3%A1, vim pelo site e tenho algumas dúvidas, poderia me ajudar ?'
            onClick={reportConversion}
          >
            <div className='button'>Fale com um Especialista!</div>
          </a>
        </div>
      )}
    </React.Fragment>
  );
};

export default EditorQuill;
