import * as React from 'react';
import {
  collection,
  DocumentData,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  QueryDocumentSnapshot,
  startAfter,
} from 'firebase/firestore';

import { firebaseFirestore } from 'services/firebase/authentication';

import { IPost } from 'types/post';

// Definindo interfaces necessárias
interface PostsHook {
  posts: IPost[];
  pendingPosts: boolean;
  loadMore: () => void;
}

// Hook personalizado para obter os posts.
// Retorna um objeto contendo uma lista de posts e um booleano indicando se o carregamento está em andamento.
export function usePosts(inputValue: string): PostsHook {
  // Definindo estados iniciais
  const [posts, setPosts] = React.useState<IPost[]>([]);
  const [pendingPosts, setPendingPosts] = React.useState(false);
  const [lastVisible, setLastVisible] = React.useState<QueryDocumentSnapshot<DocumentData>>();

  // Hook personalizado para obter os posts no Firestore.
  React.useEffect(() => {
    if (inputValue === '') {
      setPendingPosts(true);

      const unsubscribe = onSnapshot(
        query(
          collection(firebaseFirestore, 'ALL-code3c', '010500005', 'BWS-posts'),
          limit(7),
          orderBy('createdAt', 'desc')
        ),
        (snapshot) => {
          const list: IPost[] = [];

          if (snapshot.empty) {
            setPendingPosts(false);
            return;
          }

          snapshot.docs.forEach((item) => {
            list.push({ ...item.data(), id: item.id } as IPost);
          });

          setPosts(list);
          setPendingPosts(false);

          const lastDoc = snapshot.docs[snapshot.docs.length - 1];
          setLastVisible(lastDoc);
        }
      );

      return () => {
        unsubscribe();
      };
    }
    if (inputValue !== '') {
      setPendingPosts(true);

      const unsubscribe = onSnapshot(
        query(collection(firebaseFirestore, 'ALL-posts'), orderBy('createdAt', 'desc')),
        (snapshot) => {
          const list: IPost[] = [];

          if (snapshot.empty) {
            setPendingPosts(false);
            return;
          }

          snapshot.docs.forEach((item) => {
            list.push({ ...item.data(), id: item.id } as IPost);
          });

          setPosts(list.filter((pst) => pst.title.toLowerCase().includes(inputValue.toLowerCase())));
          setPendingPosts(false);
        }
      );

      return () => {
        unsubscribe();
      };
    }
  }, [inputValue]);

  const loadMore = async () => {
    setPendingPosts(true);
    const q = query(
      collection(firebaseFirestore, 'ALL-posts'),
      orderBy('createdAt', 'desc'),
      startAfter(lastVisible),
      limit(6)
    );

    const querySnapshot = await getDocs(q);

    const list: IPost[] = [];

    querySnapshot.forEach((doc) => {
      list.push({ ...doc.data(), id: doc.id } as IPost);
    });

    setPosts((prevDocuments) => [...prevDocuments, ...list]);
    setPendingPosts(false);

    const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
    setLastVisible(lastDoc);
  };

  return { posts, pendingPosts, loadMore };
}
