import * as React from 'react';
import {
  collection,
  DocumentData,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  QueryDocumentSnapshot,
  startAfter,
  where,
} from 'firebase/firestore';

import { firebaseFirestore } from 'services/firebase/authentication';
import { collections } from 'services/firebase/collections';

import { IMail } from 'types/mail';

interface MailsHook {
  mails: IMail[];
  pendingMails: boolean;
  loadMore: () => void;
  exportAvailable: () => Promise<IMail[]>;
}

export function useMails(searchValue: string): MailsHook {
  const [mails, setMails] = React.useState<IMail[]>([]);
  const [pendingMails, setPendingMails] = React.useState(false);

  const [lastVisible, setLastVisible] = React.useState<QueryDocumentSnapshot<DocumentData>>();

  React.useEffect(() => {
    if (searchValue === '') {
      setPendingMails(true);

      const unsubscribe = onSnapshot(
        query(
          collection(firebaseFirestore, 'ALL-mail'),
          where('code3c_id', '==', '010500005'),
          limit(7),
          orderBy('createdAt', 'desc')
        ),
        (snapshot) => {
          const list: IMail[] = [];

          if (snapshot.empty) {
            setPendingMails(false);
            return;
          }

          snapshot.docs.forEach((item) => {
            list.push({ ...item.data(), id: item.id } as IMail);
          });

          setMails(list);
          setPendingMails(false);

          const lastDoc = snapshot.docs[snapshot.docs.length - 1];
          setLastVisible(lastDoc);
        }
      );

      return () => {
        unsubscribe();
      };
    }
    if (searchValue !== '') {
      setPendingMails(true);

      const unsubscribe = onSnapshot(
        query(
          collection(firebaseFirestore, collections.allMail),
          where('code3c_id', '==', '010500005'),
          orderBy('createdAt', 'desc')
        ),
        (snapshot) => {
          const list: IMail[] = [];

          if (snapshot.empty) {
            setPendingMails(false);
            return;
          }

          snapshot.docs.forEach((item) => {
            list.push({ ...item.data(), id: item.id } as IMail);
          });

          setMails(list.filter((pst) => pst?.contact?.name?.toLowerCase().includes(searchValue.toLowerCase())));
          setPendingMails(false);
        }
      );

      return () => {
        unsubscribe();
      };
    }
  }, [searchValue]);

  const loadMore = async () => {
    const q = query(
      collection(firebaseFirestore, collections.allMail),
      orderBy('createdAt', 'desc'),
      where('code3c_id', '==', '010500005'),
      startAfter(lastVisible),
      limit(7)
    );

    const querySnapshot = await getDocs(q);

    const list: IMail[] = [];

    querySnapshot.forEach((doc) => {
      list.push({ ...doc.data(), id: doc.id } as IMail);
    });

    setMails((prevDocuments) => [...prevDocuments, ...list]);

    const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
    setLastVisible(lastDoc);
  };

  const exportAvailable = async () => {
    const q = query(
      collection(firebaseFirestore, collections.allMail),
      orderBy('createdAt', 'desc'),
      where('code3c_id', '==', '010500005'),
      where('contact.acceptedNews', '==', true)
    );

    const querySnapshot = await getDocs(q);

    const list: IMail[] = [];

    querySnapshot.forEach((doc) => {
      list.push({ ...doc.data(), id: doc.id } as IMail);
    });

    return list;
  };

  return { mails, pendingMails, loadMore, exportAvailable };
}
