import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

import { firebaseConnect, firebaseFirestore } from 'services/firebase/authentication';
import { collections } from 'services/firebase/collections';

import { IUser } from 'types/user';

export const signInUser = async (email: string, password: string) => {
  console.log(firebaseConnect);
  const auth = getAuth(firebaseConnect);
  const userAuth = await signInWithEmailAndPassword(auth, email, password);
  return userAuth;
};

export const searchUser = async (userId: string): Promise<IUser> => {
  const userRef = doc(firebaseFirestore, collections.allUser, userId);
  const docUser = await getDoc(userRef);

  return docUser.data() as IUser;
};
