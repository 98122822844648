import * as React from 'react';
import { doc, onSnapshot } from 'firebase/firestore';

import { firebaseFirestore } from 'services/firebase/authentication';
import { collections } from 'services/firebase/collections';

import { IUser, IUserDoc } from 'types/user';

interface UserSearchUserHook {
  user: IUser | undefined;
  pendingUser: boolean;
}

/**
 * @param {string }userId
 * @type {React.ReactElement}
 * @return {React.ReactElement}
 */
export function useSearchUser(userId: string | undefined): UserSearchUserHook {
  const [user, setUser] = React.useState<IUser>();
  const [pendingUser, setPendingUser] = React.useState(false);

  React.useEffect(() => {
    if (userId) {
      setPendingUser(true);

      const unsubscribe = onSnapshot(doc(firebaseFirestore, collections.allUser, userId), (snapshot) => {
        if (snapshot.exists()) {
          const newUser = snapshot.data() as IUserDoc;
          setUser({ ...newUser, id: snapshot.id });
        }
        setPendingUser(false);
      });

      return () => {
        unsubscribe();
      };
    }
  }, [userId]);

  return { user, pendingUser };
}
