import { FirebaseError } from 'firebase/app';

export const parseFirebaseErrors = (error: FirebaseError): string => {
  switch (error.code) {
    case 'auth/invalid-email':
    case 'auth/wrong-password':
    case 'auth/user-not-found':
      return 'Dados de autênticação inválidos.';
    case 'invalid-argument':
      return 'Algo deu errado, Verifique novamente os dados.';
    default:
      return 'Algo deu errado, contate o administrador do sistema.';
  }
};
