import React from 'react';

import { useParams } from 'react-router-dom';

import { Skeleton } from 'primereact/skeleton';

import './skeleton.scss';

const SkeletonCard: React.FC = () => {
  const { viewId } = useParams();

  return (
    <React.Fragment>
      <div className='skeleton-container-post'>
        <div className={`first-container ${!viewId ? 'width100' : ''}`}>
          <Skeleton className='emphasis' />
        </div>
        {viewId && (
          <div className='second-container'>
            <div>
              {[1, 2, 3].map((item, index) => {
                return <Skeleton key={index} className='card' />;
              })}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default SkeletonCard;
