import * as React from 'react';

import { InputSwitch, InputSwitchProps } from 'primereact/inputswitch';

import { BasicInputErrors } from 'components/inputs/basicInputErrors/basicInputErrors';

import { YupTypesError } from 'types/yupTypes';

import './basicSwitch.scss';

export interface BasicSwitchProps extends InputSwitchProps {
  inputId: string;
  mandatory?: boolean;
  label?: string;
  disabled?: boolean;
  validationErrors?: YupTypesError[];
  customClassName?: string;
}
export const BasicSwitch = (props: BasicSwitchProps) => {
  const { inputId, mandatory, label, disabled, validationErrors, customClassName } = props;

  const isInvalid = (validationErrors: Array<YupTypesError>): boolean => {
    if (validationErrors.find((errors) => errors.path === inputId)) {
      return true;
    }
    return false;
  };

  return (
    <div className={`basic-switch ${customClassName ? customClassName + ' ' + props.className : props.className}`}>
      <InputSwitch
        id={inputId}
        className={`field ${validationErrors && isInvalid(validationErrors) && 'invalid'}`}
        disabled={disabled}
        {...props}
      />
      <label htmlFor={inputId}>{(label || '') + (mandatory ? ' *' : '')}</label>
      {inputId && validationErrors && <BasicInputErrors inputId={inputId} validationErrors={validationErrors} />}
    </div>
  );
};
