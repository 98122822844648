import React from 'react';

import {
  FaBars,
  FaBook,
  FaBookOpenReader,
  FaBriefcase,
  FaEnvelope,
  FaFacebook,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaPhone,
  FaQuoteLeft,
  FaTrash,
  FaTwitter,
  FaUser,
  FaWhatsapp,
  FaXmark,
  FaYoutube,
} from 'react-icons/fa6';

import './basicIcon.scss';

interface BasicIconProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  iconName: string;
}

interface IIcons {
  [string: string]: React.ReactElement;
}

const BasicIcon: React.FC<BasicIconProps> = ({ iconName, ...props }) => {
  const icons: IIcons = {
    FaBars: <FaBars />,
    FaBriefcase: <FaBriefcase />,
    FaBookOpenReader: <FaBookOpenReader />,
    FaXmark: <FaXmark />,
    FaWhatsapp: <FaWhatsapp />,
    FaFacebook: <FaFacebook />,
    FaInstagram: <FaInstagram />,
    FaTwitter: <FaTwitter />,
    FaYoutube: <FaYoutube />,
    FaEnvelope: <FaEnvelope />,
    FaQuoteLeft: <FaQuoteLeft />,
    FaBook: <FaBook />,
    FaPhone: <FaPhone />,
    FaUser: <FaUser />,
    FaLinkedinIn: <FaLinkedinIn />,
    FaFacebookF: <FaFacebookF />,
    FaTrash: <FaTrash />,
  };

  return <i {...props}> {icons[iconName]}</i>;
};

export default BasicIcon;
