import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { addPost, deletePost, editPost } from 'controller/blog';
import { getStorage, ref, uploadString } from 'firebase/storage';

import { useNavigate, useParams } from 'react-router-dom';

import { BreadCrumb } from 'primereact/breadcrumb';

import { Auth } from 'contexts/auth.context';
import { NavigatorContext } from 'contexts/navigator.context';
import { Toaster } from 'contexts/toast.context';

import { IPost } from 'types/post';
import { YupTypesError } from 'types/yupTypes';

import CommentsFacebook from './components/commentsFacebook';
import EditorQuill from './components/editorQuill';
import EmphasisImage from './components/emphasisImage';
import Header from './components/header';
import RecentPosts from './components/recentPosts';
import Share from './components/share';
import SimilarPosts from './components/similarPosts';
import SkeletonCard from './components/skeleton';
import Tags from './components/tags';
import { usePostSingle } from './hooks/usePostSingle';
import { useRecentPosts } from './hooks/useRecentPosts';
import { useSimilarPosts } from './hooks/useSimilarPost';
import { dataSchema, dataSchemaNotImage } from './post.validations';

import './post.scss';

const Post: React.FC = () => {
  const navigate = useNavigate();

  const { viewId, id } = useParams();

  const { user } = React.useContext(Auth);
  const { toastError, toastSuccess } = React.useContext(Toaster);
  const { setterNavigator, setterEnableAdminNav, setterEnableHeader, setterEnableControlPannel } =
    React.useContext(NavigatorContext);

  const [tags, setTags] = React.useState<string[]>([]);
  const [documentId, setDocumentId] = React.useState<string>('');
  const [title, setTitle] = React.useState<string>('');
  const [url, setUrl] = React.useState<string>('');
  const [date, setDate] = React.useState<Date>(new Date());
  const [author, setAuthor] = React.useState<string>('');
  const [article, setArticle] = React.useState<string>('');
  const [image, setImage] = React.useState<string>('https://via.placeholder.com/1920x1080');
  const [imageName, setImageName] = React.useState<string>('');
  const [imageChange, setImageChange] = React.useState<boolean>(false);
  const [preview, setPreview] = React.useState<boolean>(false);

  const [validationErrors, setValidationErrors] = React.useState<YupTypesError[]>([]);

  const { singlePost, pendingSinglePost } = usePostSingle(viewId ? viewId : id ? id : '');
  const { similarPost, pendingSimilarPost } = useSimilarPosts(tags ? tags : ['']);
  const { recentPosts, pendingRecentPosts } = useRecentPosts();

  const filterRecentPosts = recentPosts.filter((item) => item.id !== documentId).filter((item, index) => index < 3);

  const filterSimilarPosts = similarPost.filter(
    (item1) => !filterRecentPosts.find((item2) => item2.title === item1.title) && item1.id !== documentId
  );

  const items = [{ label: 'Blog', command: () => navigate('/blog') }, { label: 'Post' }];
  const home = { icon: 'pi pi-home', url: '/' };

  async function addPostFunction() {
    const storage = getStorage();

    const storageRef = ref(storage, `blog-images/${imageName}`);

    uploadString(storageRef, image, 'data_url')
      .then(async () => {
        const response = await addPost({
          title: title,
          url: url,
          article: article,
          image: imageName,
          author: user?.name,
          tags: tags,
        });
        if (response) {
          toastSuccess('Post Adicionado com Sucesso!');
          navigate('/admin/painel');
        }
      })
      .catch(() => {
        toastError('Não foi possível adicionar a imagem, Tente novamente mais tarde.');
      });
  }

  async function editPostFunction() {
    if (imageChange) {
      const storage = getStorage();

      const storageRef = ref(storage, `blog-images/${imageName}`);

      uploadString(storageRef, image, 'data_url')
        .then(async () => {
          const response = await editPost({ article: article, image: imageName, tags: tags }, documentId);
          if (response) {
            toastSuccess('Post Editado com Sucesso com Imagem!');
            navigate('/admin/painel');
          }
        })
        .catch(() => {
          toastError('Não foi possível adicionar a imagem, Tente novamente mais tarde.');
        });
    } else {
      const response = await editPost({ article: article, tags: tags }, documentId);
      if (response) {
        toastSuccess('Post Editado com Sucesso Sem Imagem!');
        navigate('/admin/painel');
      }
    }
  }

  const functionAddEdit = (type: string) => {
    const dataValidation: Partial<IPost> = {
      title: title,
      url: url,
      article: article,
      image: imageName,
      author: user?.name,
      tags: tags,
    };

    if (type === 'edit' && imageChange) {
      delete dataValidation.image;
    }

    const newDataSchema = viewId || !id ? dataSchema : dataSchemaNotImage;

    newDataSchema
      .validate(dataValidation, { abortEarly: false })
      .then(async () => {
        type === 'add' ? addPostFunction() : editPostFunction();
      })
      .catch((err: YupTypesError) => {
        if (err) {
          console.log(err);
          setValidationErrors(err.inner);
        } else {
          setValidationErrors([]);
        }
      });
  };

  const functionDelete = async () => {
    const response = await deletePost(documentId);

    if (response) {
      toastSuccess('Post Deletado com Sucesso!');
      navigate('/admin/painel');
    } else {
      toastError('Não foi possível deletar o Post!');
    }
  };

  const ComponentRecentPosts = () => {
    if (viewId && filterRecentPosts.length !== 0) {
      return (
        <div className='second-container'>
          <div className='second-content'>
            {filterRecentPosts.map((item, index) => {
              return <RecentPosts key={index} item={item} />;
            })}
          </div>
        </div>
      );
    }
  };

  const ComponentSimilarPosts = () => {
    if (viewId && similarPost?.filter((item) => item.id !== documentId).length !== 0) {
      return <SimilarPosts similarPosts={filterSimilarPosts} documentId={documentId} />;
    }
  };

  const ComponentShare = () => {
    if (viewId) {
      return <Share />;
    }
  };

  const ComponentCommentsFacebook = () => {
    if (viewId) {
      return <CommentsFacebook url={url} />;
    }
  };

  React.useEffect(() => {
    if (singlePost) {
      setDocumentId(singlePost.id);
      setArticle(singlePost.article);
      setTitle(singlePost.title);
      setAuthor(singlePost.author);
      setDate(singlePost.createdAt.toDate());
      setUrl(singlePost.url);
      setTags(singlePost.tags);
      setImage(viewId || id ? singlePost.image : 'https://via.placeholder.com/1920x1080');
    }
  }, [id, singlePost, viewId]);

  React.useEffect(() => {
    if (!viewId || !id) {
      setDocumentId('');
      setArticle('');
      setTitle('');
      setAuthor('');
      setDate(new Date());
      setUrl('');
      setTags([]);
      setImage('https://via.placeholder.com/1920x1080');
    }
  }, [id, viewId]);
  // LINT WARN - window.location.pathname

  React.useEffect(() => {
    if (!viewId) {
      setterEnableAdminNav(true);
      setterEnableHeader(true);
      setterEnableControlPannel(true);
    } else {
      setterEnableAdminNav(false);
    }
    setterNavigator('RELATIVE');
  }, [setterEnableAdminNav, setterEnableControlPannel, setterEnableHeader, setterNavigator, viewId]);
  // LINT WARN - window.location.pathname

  React.useEffect(() => {
    if (viewId && singlePost) {
      ReactGA.send({ hitType: 'pageview', page: `/blog/${singlePost.url}` });
      ReactPixel.pageView();
    }
  }, [singlePost, viewId]);

  return (
    <div className='blog-post-container'>
      <div className='container'>
        <div className='page-title'>Blog</div>
        {pendingRecentPosts || pendingSimilarPost || pendingSinglePost ? (
          <SkeletonCard />
        ) : (
          <div className='body'>
            <div className={`first-container ${!viewId ? 'width100' : ''}`}>
              <div className='first-content'>
                {viewId && <BreadCrumb model={items} style={{ marginBottom: '1rem', width: 'auto' }} home={home} />}
                <Header
                  viewId={viewId}
                  id={id}
                  preview={preview}
                  setPreview={setPreview}
                  handleAdd={functionAddEdit}
                  handleEdit={functionAddEdit}
                  title={title}
                  author={author}
                  date={date}
                  setTitle={setTitle}
                  setUrl={setUrl}
                  validationErrors={validationErrors}
                  functionDelete={functionDelete}
                />
                <EmphasisImage
                  viewId={viewId}
                  image={image}
                  setImageChange={setImageChange}
                  setImageName={setImageName}
                  setImage={setImage}
                  id={id}
                  validationErrors={validationErrors}
                  imageChange={imageChange}
                />
                <EditorQuill
                  viewId={viewId}
                  preview={preview}
                  article={article}
                  setArticle={setArticle}
                  validationErrors={validationErrors}
                />
                <ComponentShare />
                <Tags tags={tags} setTags={setTags} preview={preview} validationErrors={validationErrors} />
                <ComponentSimilarPosts />
                <ComponentCommentsFacebook />
              </div>
            </div>
            <ComponentRecentPosts />
          </div>
        )}
      </div>
    </div>
  );
};

export default Post;
