import React from 'react';

import BasicIcon from 'components/icon/basicIcon/basicIcon';

import './share.scss';

const Share: React.FC = () => {
  const pathname = window.location.pathname;

  const twitter = `https://twitter.com/intent/tweet?url=https://froesecalazans.com.br${pathname}`;
  const facebook = `https://www.facebook.com/sharer/sharer.php?u=https://froesecalazans.com.br${pathname}`;
  const linkedin = `http://www.linkedin.com/shareArticle?mini=true&url=https://froesecalazans.com.br${pathname}`;

  return (
    <div className='share'>
      <div className='title-share'>Compartilhe em sua Rede Social:</div>
      <div className='icon-container'>
        <div className='hex' onClick={() => window.open(facebook, 'mywin', 'width=600,height=400')}>
          <BasicIcon iconName='FaFacebookF' className='icon' />
        </div>
        <div className='hex'>
          <BasicIcon
            iconName='FaTwitter'
            className='icon'
            onClick={() => window.open(twitter, 'mywin', 'width=600,height=400')}
          />
        </div>
        <div className='hex'>
          <BasicIcon
            iconName='FaLinkedinIn'
            className='icon'
            onClick={() => window.open(linkedin, 'mywin', 'width=600,height=400')}
          />
        </div>
      </div>
    </div>
  );
};

export default Share;
