import * as React from 'react';

import { Carousel } from 'primereact/carousel';

import { BasicButton } from 'components/buttons/basicButton/basicButton';
import BasicIcon from 'components/icon/basicIcon/basicIcon';

import { Testimonies, testimonies } from './data/testimonies';

import './testimony.scss';

const TestimonyCarouselEmphasis: React.FC = () => {
  const carouselEmphasis: Testimonies[] = testimonies;

  const carouselTemplate = (emphasis: Testimonies) => {
    return (
      <div className='testimony-carousel-item'>
        <h1>
          <BasicIcon iconName='FaQuoteLeft' />
        </h1>
        <p>{emphasis.testimony}</p>
        <div className='testimony-carousel-item-author'>
          <img src={emphasis.authorImage} />
          <div className='testimony-carousel-item-author-info'>
            <p>
              <b>{emphasis.author}</b>
            </p>
            <p>
              <b>{emphasis.authorPosition}</b>
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='testimony-carousel'>
      <Carousel
        value={carouselEmphasis}
        numVisible={1}
        numScroll={1}
        circular
        showNavigators={false}
        autoplayInterval={5000}
        orientation='horizontal'
        itemTemplate={carouselTemplate}
        pt={{
          indicatorButton: { className: 'testimony-carousel-indicator' },
        }}
      />
    </div>
  );
};

const Testimony = () => {
  return (
    <div id='depoimentos' className='container reveal'>
      <div className='content'>
        <div className='testimony-info'>
          <h1 className='testimony-info-title'>Veja o que nossos Alunos estão falando sobre nós!</h1>
          <h5 className='testimony-info-emphasis'>Alguns testemunhos dos nossos alunos.</h5>
          <div className='testimony-info-button'>
            <BasicButton
              customClassName='testimony-info-button-input'
              label='Veja a nossa lista de cursos'
              severity='info'
              onClick={() =>
                window.open(
                  'https://firebasestorage.googleapis.com/v0/b/psh-fca.appspot.com/o/docs-cruzeiro%2Fcursos%20cruzeiro.pdf?alt=media&token=f2099d28-80a2-4615-b9fc-871e39abba60&_gl=1*13cgaxy*_ga*MTE0MjA1MDgxMi4xNjg4MDYyMjQ2*_ga_CW55HF8NVT*MTY5ODc2MDk2My4xODYuMS4xNjk4NzY2MDY4LjE5LjAuMA..'
                )
              }
            />
          </div>
        </div>
        <div className='testimony-carousel-area'>
          <TestimonyCarouselEmphasis />
        </div>
        <div className='testimony-see-more-button'>
          <BasicButton
            label='Veja a nossa lista de cursos'
            severity='info'
            onClick={() =>
              window.open(
                'https://firebasestorage.googleapis.com/v0/b/psh-fca.appspot.com/o/docs-cruzeiro%2Fcursos%20cruzeiro.pdf?alt=media&token=f2099d28-80a2-4615-b9fc-871e39abba60&_gl=1*13cgaxy*_ga*MTE0MjA1MDgxMi4xNjg4MDYyMjQ2*_ga_CW55HF8NVT*MTY5ODc2MDk2My4xODYuMS4xNjk4NzY2MDY4LjE5LjAuMA..'
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Testimony;
