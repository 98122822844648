import * as React from 'react';
import { doc, onSnapshot } from 'firebase/firestore';

import { firebaseFirestore } from 'services/firebase/authentication';

type CollectionOptions = 'blog' | 'email';

interface OptionsHook {
  options: IOptions | undefined;
  pendingOptions: boolean;
}

interface IOptions {
  id: string;
  count: number;
}

export function useSearchOptions(collectionOptions: CollectionOptions): OptionsHook {
  const [options, setOptions] = React.useState<IOptions>();
  const [pendingOptions, setPendingOptions] = React.useState(false);

  React.useEffect(() => {
    setPendingOptions(true);

    const unsubscribe = onSnapshot(
      doc(firebaseFirestore, 'ALL-code3c', '010500005', 'BWS-shard', collectionOptions),
      (snapshot) => {
        if (snapshot.exists()) {
          const newUser = snapshot.data() as IOptions;
          setOptions({ ...newUser, id: snapshot.id });
          setPendingOptions(false);
        }
        setPendingOptions(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [collectionOptions]);

  return { options, pendingOptions };
}
