import * as React from 'react';

import { AuthProvider } from './auth.context';
import { NavigatorProvider } from './navigator.context';
import { ToasterProvider } from './toast.context';

// import { AuthProvider } from './auth.context';

interface IProps {
  children: React.ReactNode;
}

const MainContext: React.FC<IProps> = ({ children }) => {
  return (
    <AuthProvider>
      <NavigatorProvider>
        <ToasterProvider>{children}</ToasterProvider>
      </NavigatorProvider>
    </AuthProvider>
  );
};

export default MainContext;
