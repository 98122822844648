import * as React from 'react';
import freecourses from 'assets/images/cursoslivres.jpg';
import graduation from 'assets/images/graduacao.jpg';
import poslato from 'assets/images/posgraduacaolato.jpg';
import posstricto from 'assets/images/posgraduacaostricto.jpg';

import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import Course from './components/card';

import './courses.scss';

/* eslint-disable */
const Courses: React.FC = () => {
  const dataCourses = [
    {
      title: 'Graduação',
      description:
        'Quando você faz um curso de graduação na modalidade EAD tem diversas vantagens: além da flexibilidade de tempo, que permite estudar em qualquer horário ou local, bastando, para isso, ter um aparelho com conexão à internet, ele possui mensalidades mais acessíveis. Tudo isso com diploma sem diferenciação, afinal, os cursos EAD são reconhecidos e regulamentados pelo Ministério da Educação (MEC).',
      image: <img src={graduation}/>,
      sequence: 'first',
      logo: ''
    },
    {
      title: 'Pós Graduação lato sensu',
      description:
        'A concorrência no mercado de trabalho só cresce. Cada vez mais especializados, os profissionais que disputam uma vaga precisam não só se destacar profissionalmente, mas também apresentar boas referências e conhecimentos especializados.',
      image: <img src={poslato}/>,
      sequence: 'second',
      logo: ''
    },
    {
      title: 'Pós Graduação stricto sensu',
      description:
      'Os cursos de Pós EaD superam todas as limitações físicas. Presentes em diferentes estados, eles conseguem trazer o conhecimento de ponta até sua casa. Em variadas áreas do conhecimento, os cursos conseguem abarcar MBAs em Gestão Pública, Gestão Estratégica de Pessoas, Comunicação e Marketing, Gestão Empresarial e Supply Chain e Logística Integrada, por exemplo.',
      image: <img src={posstricto}/>,
      sequence: 'first',
      logo: '',
    },
    {
      title: 'Cursos livres',
      description:
      'Pensando em atualizar seus conhecimentos para se destacar no mercado de trabalho, mas está com pouco tempo? Os cursos livres oferecem diversas vantagens, como dar um upgrade no currículo, aprofundar conhecimentos, explorar novas áreas e ampliar as oportunidades no mercado de trabalho.',
      image: <img src={freecourses}/>,
      sequence: 'second',
      logo: '',
    }
  ];
  return (
    <div id='cursos' className='container'>
      <div className='content reveal'>
        <div className='title-content'>
          <div className='title'>Explore os nossos Cursos</div>
          <div className='description'>Quem escolheu o Institulo Pinheiros, amou sua experiência universitária.</div>
        </div>
        <div className='cards-courses'>
          {dataCourses.map((course, index)=>
            <Course key={course.title + index} image={course.image} title={course.title} description={course.description} sequence={course.sequence} index={index}/>
          )}
        </div>
      </div>
    </div>
  );
};

export default Courses;
