import React from 'react';

import { useNavigate } from 'react-router-dom';

import { format } from 'date-fns';

import { Skeleton } from 'primereact/skeleton';

import { NavigatorContext } from 'contexts/navigator.context';

import { BasicButton } from 'components/buttons/basicButton/basicButton';
import { BasicInput } from 'components/inputs/basicInput/basicInput';
import { AuthLoading } from 'components/loadings/authLoading/authLoading';

import { useSearchOptions } from 'hooks/useOptions';
import { usePosts } from 'hooks/usePosts';

import { IPost } from 'types/post';

import logoOnly from '../../assets/images/logo.png';

import './pannelPosts.scss';

const PannelPostsItem: React.FC<{ post: IPost; onClick: (id: string) => void }> = ({ post, onClick }) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div className='pannel-posts-item' onClick={() => onClick(post.url)}>
      <div className='pannel-posts-item-image'>
        {!imageLoaded && <Skeleton className='pannel-posts-item-image-skeleton' />}
        <img
          src={`https://firebasestorage.googleapis.com/v0/b/erp-epsys.appspot.com/o/blog-images%2F${post.image}?alt=media`}
          onLoad={handleImageLoad}
          style={{ display: !imageLoaded ? 'none' : 'flex' }}
        />
      </div>
      <div className='pannel-posts-item-info'>
        <p className='pannel-posts-item-info-title'>{post.title}</p>
        <p>
          Publicado: <b>{format(post?.createdAt.toDate(), 'dd/LL/yyyy - H:mm')}</b>
        </p>
        {format(post?.updatedAt.toDate(), 'dd/LL/yyyy - H:mm:ss') !==
          format(post?.createdAt.toDate(), 'dd/LL/yyyy - H:mm:ss') && (
          <p>
            Editado: <b>{format(post?.updatedAt.toDate(), 'dd/LL/yyyy - H:mm')}</b>
          </p>
        )}
      </div>
      <div className='pannel-posts-item-actions'>
        <p>
          Por <b>{post.author}</b>
        </p>
      </div>
    </div>
  );
};

const PannelPosts: React.FC = () => {
  const { setterNavigator, setterEnableAdminNav, setterEnableControlPannel, setterEnableHeader } =
    React.useContext(NavigatorContext);

  const history = useNavigate();

  const [searchPost, setSearchPost] = React.useState<string>('');

  const { posts, pendingPosts, loadMore } = usePosts(searchPost);
  const { options, pendingOptions } = useSearchOptions('blog');

  const [internalInputValue, setInternalInputValue] = React.useState('');

  const editPost = (url: string) => {
    history(`/admin/blog/editar/${url}`);
  };

  React.useEffect(() => {
    setterNavigator('RELATIVE');
    setterEnableAdminNav(true);
    setterEnableControlPannel(true);
    setterEnableHeader(true);
  }, [setterEnableAdminNav, setterEnableControlPannel, setterEnableHeader, setterNavigator]);

  const ComponentMorePosts = () => {
    if (posts.length !== options?.count && posts.length > 0 && searchPost === '') {
      return (
        <BasicButton
          className='button-load-more'
          label='Carregar mais'
          onClick={() => loadMore()}
          disabled={pendingPosts || pendingOptions}
        />
      );
    }
  };

  return (
    <div className={`pannel-posts ${posts.length == 0 ? 'pannel-posts-no-posts' : ''}`}>
      {pendingPosts ? (
        <AuthLoading />
      ) : posts.length > 0 ? (
        <React.Fragment>
          <div className='pannel-posts-searchbar'>
            <BasicInput
              placeholder='Pesquisar'
              type='text'
              iconName='FaMagnifyingGlass'
              iconPosition='LEFT'
              onChange={(e) => setInternalInputValue(e.target.value)}
              customClassName='pannel-posts-searchbar-input'
              value={internalInputValue}
            />
            <BasicButton
              className='button-search'
              label='Pesquisar'
              onClick={() => setSearchPost(internalInputValue)}
            />
          </div>
          {posts.map((post, index) => {
            return <PannelPostsItem post={post} key={String(index)} onClick={(id: string) => editPost(id)} />;
          })}
          {posts.length === 0 && (
            <div className='pannel-posts-no-posts-logo'>
              <p>Nenhuma postagem encontrada</p>
            </div>
          )}
          <ComponentMorePosts />
        </React.Fragment>
      ) : (
        <div className='pannel-posts-no-posts-logo'>
          <img src={logoOnly} />
          <p>Nenhuma postagem</p>
        </div>
      )}
    </div>
  );
};

export default PannelPosts;
