import React from 'react';

import { Galleria } from 'primereact/galleria';

import { images } from './data/images';

import './gallery.scss';

const Gallery: React.FC = () => {
  const [activeIndex, setActiveIndex] = React.useState<number>(0);

  const galleria = React.useRef<Galleria | null>(null);

  const itemTemplate = (item: string) => {
    return <img src={item} style={{ width: '100%', display: 'block', maxHeight: '80vh' }} />;
  };

  const thumbnailTemplate = (item: string) => {
    return <img src={item} style={{ display: 'block' }} />;
  };

  function showImage(index: number) {
    setActiveIndex(index);
    galleria?.current?.show();
  }

  return (
    <div id='gallery' className='gallery-container reveal'>
      <div className='title'>Nossa Galeria</div>
      <div className='subtitle'>Nossas Fotos e Videos</div>
      <Galleria
        ref={galleria}
        value={images}
        numVisible={7}
        style={{ maxWidth: '850px' }}
        activeIndex={activeIndex}
        onItemChange={(e) => setActiveIndex(e.index)}
        circular
        fullScreen
        showItemNavigators
        showThumbnails={false}
        item={itemTemplate}
        thumbnail={thumbnailTemplate}
      />
      <div className='gallery'>
        <div className='group'>
          <div className='column'>
            <img className='image' src={images[0]} onClick={() => showImage(0)} />
          </div>
          <div className='column2'>
            <div className='row1'>
              <img className='image' src={images[1]} onClick={() => showImage(1)} />
            </div>
            <div className='row2'>
              <img className='image' src={images[2]} onClick={() => showImage(2)} />
            </div>
          </div>
        </div>
        <div className='group'>
          <div className='column'>
            <img className='image' src={images[3]} onClick={() => showImage(3)} />
          </div>
          <div className='column2'>
            <div className='row1'>
              <img className='image' src={images[4]} onClick={() => showImage(4)} />
            </div>
            <div className='row2'>
              <img className='image' src={images[5]} onClick={() => showImage(5)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
