import * as React from 'react';

import yellowArrow from './assets/sectionArrow.svg';
import whiteArrow from './assets/sectionArrowWhite.svg';

import './sectionBar.scss';

type ComponentType = 'home' | 'aboutUs';

interface SectionBarProps {
  component: ComponentType;
}

const SectionBar: React.FC<SectionBarProps> = ({ component }) => {
  return (
    <div className={`section-bar ${component}`}>
      <div className='section-bar-area' />
      <img src={component === 'home' ? yellowArrow : whiteArrow} className='section-bar-arrow' />
    </div>
  );
};

export { SectionBar };
