import React from 'react';

import { Skeleton } from 'primereact/skeleton';

import { Toaster } from 'contexts/toast.context';

import { BasicInputErrors } from 'components/inputs/basicInputErrors/basicInputErrors';

import { previewImage } from 'utils/blog';

import { YupTypesError } from 'types/yupTypes';

import './emphasisImage.scss';

interface IProps {
  viewId: string | undefined;
  id: string | undefined;
  image: string;
  setImageChange: React.Dispatch<React.SetStateAction<boolean>>;
  setImageName: React.Dispatch<React.SetStateAction<string>>;
  setImage: React.Dispatch<React.SetStateAction<string>>;
  validationErrors: YupTypesError[];
  imageChange: boolean;
}

const EmphasisImage: React.FC<IProps> = ({
  viewId,
  image,
  setImageChange,
  setImageName,
  setImage,
  id,
  validationErrors,
  imageChange,
}) => {
  const { toastError } = React.useContext(Toaster);

  const [imageLoaded, setImageLoaded] = React.useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  async function handleImage(e: React.ChangeEvent<HTMLInputElement>) {
    setImageChange(true);

    if (e.target) {
      if (e.target.files && e.target.files[0]) {
        const allowedTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/bmp'];

        if (!allowedTypes.includes(e.target.files[0]?.type)) {
          toastError('Somente imagens JPEG, PNG, e BPM são permitidas.');
        } else if (allowedTypes.includes(e.target.files[0]?.type)) {
          setImageName(e.target.files ? `${new Date().getTime().toString()}-${e.target.files[0].name}` : '');
          previewImage(e.target.files[0], setImage);
        }
      }
    }
  }

  return (
    <div className='image-container-emphasis'>
      {viewId ? (
        <React.Fragment>
          {!imageLoaded && <Skeleton width='100%' height='25rem' />}
          <img
            className='image not-cursor'
            src={`https://firebasestorage.googleapis.com/v0/b/erp-epsys.appspot.com/o/blog-images%2F${image}?alt=media`}
            onLoad={handleImageLoad}
            style={{ display: !imageLoaded ? 'none' : 'flex' }}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <input
            type='file'
            name='file'
            id='file'
            accept='.jpg,.jpeg,.bmp,.png'
            style={{ display: 'none' }}
            onChange={(e) => handleImage(e)}
          />
          <label htmlFor='file' className='html-image'>
            {!imageLoaded && <Skeleton width='100%' height='25rem' />}
            <img
              className='image'
              src={
                (viewId || id) && !imageChange
                  ? `https://firebasestorage.googleapis.com/v0/b/erp-epsys.appspot.com/o/blog-images%2F${image}?alt=media`
                  : image
              }
              onLoad={handleImageLoad}
              style={{ display: !imageLoaded ? 'none' : 'flex' }}
            />
          </label>
          <div> {validationErrors && <BasicInputErrors inputId='image' validationErrors={validationErrors} />}</div>
        </React.Fragment>
      )}
    </div>
  );
};

export default EmphasisImage;
