import * as React from 'react';
import { searchUser } from 'controller/userAuth';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';

import { usePrevious } from 'primereact/hooks';

import { useSearchUser } from 'hooks/useSearchUser';

import { firebaseConnect } from 'services/firebase/authentication';

import { IUser } from 'types/user';

interface IProps {
  children: React.ReactNode;
}

interface IContext {
  authGrant: boolean;
  user: IUser | undefined;
  language: string | undefined;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  accessToken: string;
  pendingUser: boolean;
  clearAuthorization: () => void;
}

export const Auth = React.createContext<Partial<IContext>>({});

export const AuthProvider: React.FC<IProps> = ({ children }) => {
  const auth = getAuth(firebaseConnect);

  const [userUid, setUserUid] = React.useState<string>();
  const [accessToken, setAccessToken] = React.useState<string>('');

  const { user, pendingUser } = useSearchUser(userUid);

  const previousUser = usePrevious(user);

  const [authUser, setAuthUser] = React.useState<IUser | undefined>();

  const [authGrant, setAuthGrant] = React.useState<boolean>(false);

  const clearAuthorization = React.useCallback(() => {
    signOut(auth).then(() => {
      setAuthGrant(false);
      setAuthUser(undefined);
      setUserUid('');
      setAccessToken('');
      window.location.reload();
    });
  }, [auth]);

  React.useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userToken = await user.getIdTokenResult(false);
        setAccessToken(userToken.token);
        setUserUid(user.uid);

        if (user.uid) {
          const userData = await searchUser(user.uid);
          if (userData.code3c_id === '010500005') {
            setAuthUser(userData);
            setAuthGrant(true);
          } else {
            clearAuthorization();
          }
        }
      }
    });
  }, [auth, clearAuthorization]);

  // Renova e Mantém as atualizações automáticas de user.
  React.useEffect(() => {
    if (user && user !== previousUser) {
      setAuthUser(user);
    }
  }, [user, previousUser]);

  // Renovação do Token da autenticação Firebase.
  // Necessário apenas as Cloud Functions do Firebase.
  React.useEffect(() => {
    const interval = setInterval(() => {
      onAuthStateChanged(auth, async (user) => {
        try {
          if (user) {
            const userToken = await user.getIdTokenResult(false);
            setAccessToken(userToken.token);
          }
        } catch (error) {
          console.error('Erro ao Renovar Token');
        }
      });
    }, 1000 * 60 * 60);
    return () => clearInterval(interval);
  }, [auth]);

  // if (pending || pendingUser) {
  //   return <AuthLoading fullscreen />;
  // }

  return (
    <Auth.Provider
      value={{
        user: authUser,
        accessToken,
        pendingUser,
        authGrant,
        clearAuthorization,
      }}
    >
      {children}
    </Auth.Provider>
  );
};
