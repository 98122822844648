import React from 'react';

import { FaAngleUp } from 'react-icons/fa6';

import './backToTop.scss';

const clickBackToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

const BackToTop: React.FC = () => {
  const [enableBackToTop, setEnableBackToTop] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 200) {
        setEnableBackToTop(true);
      } else {
        setEnableBackToTop(false);
      }
    });
  }, []);

  return (
    <div className='back-to-top' style={{ opacity: enableBackToTop ? '0.6' : '0' }}>
      <div
        className='back-to-top-button'
        style={{ cursor: enableBackToTop ? 'pointer' : '0' }}
        onClick={clickBackToTop}
      >
        <FaAngleUp />
      </div>
    </div>
  );
};

export default BackToTop;
