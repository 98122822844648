import * as React from 'react';
import CountUp from 'react-countup';

import { Carousel } from 'primereact/carousel';

import { SectionBar } from 'components/bars/sectionbar/sectionBar';
import { BasicButton } from 'components/buttons/basicButton/basicButton';
import BasicIcon from 'components/icon/basicIcon/basicIcon';

import './home.scss';

const HomeCarouselEmphasis: React.FC = () => {
  const carouselEmpashis = [
    'Oferecemos Cursos Técnicos, de Graduação, Pós-Graduação(  Lato Sensu e Stricto Sensu), de alta qualidade,  com excelentes notas no MEC, para sua formação e desenvolvimento Acadêmico.',
    'Somos Parceiros Oficiais da Cruzeiro do Sul Virtual e da Must University, Garantindo a Melhor Educação para você se preparar para o mercado de trabalho.',
    'Destaque-se Profissionalmente com o Instituto Pinheiro - Sua Rota para o Sucesso no Mundo Acadêmico e Profissional.',
  ];

  const carouselTemplate = (empashis: string) => {
    return <p>{empashis}</p>;
  };

  return (
    <div className='home-carousel'>
      <Carousel
        value={carouselEmpashis}
        numVisible={1}
        numScroll={1}
        circular
        showNavigators={false}
        autoplayInterval={5000}
        orientation='horizontal'
        itemTemplate={carouselTemplate}
        pt={{
          indicators: { className: 'home-carousel-indicators' },
          indicatorButton: { className: 'home-carousel-indicator' },
        }}
      />
    </div>
  );
};

interface HomeCardProps {
  icon: string;
  preffix?: string;
  counter: number;
  suffix?: string;
  title: string;
}

const HomeCard: React.FC<HomeCardProps> = (props) => {
  const { icon, counter, preffix, suffix, title } = props;
  return (
    <div className='home-card-body'>
      <BasicIcon iconName={icon} />

      <CountUp
        delay={2}
        prefix={preffix}
        separator=''
        end={counter}
        suffix={suffix}
        duration={3}
        className='home-card-counter'
      />
      <div className='home-card-title'>{title}</div>
    </div>
  );
};

const Home: React.FC = () => {
  return (
    <div id='home' className='home-container'>
      <div className='background-gradient'>
        <div className='background-image'>
          <div className='home-content'>
            <div className='home-info'>
              <h1>Bem-vindo ao Instituto Pinheiro.</h1>
              <h2>Excelência em Educação Superior!</h2>
              <HomeCarouselEmphasis />
              <a href='/#cursos'>
                <BasicButton label='VER MAIS' severity='secondary' customClassName='home-button' />
              </a>
            </div>
            <div className='home-cards'>
              <HomeCard icon='FaBookOpenReader' preffix='+' counter={2500} title='Alunos Cadastrados' />
              <HomeCard icon='FaBriefcase' counter={56000} title='Empresas parceiras' />
            </div>
            <SectionBar component='home' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
