import React from 'react';

import { format } from 'date-fns';

import { Skeleton } from 'primereact/skeleton';

import { IPost } from 'types/post';

import './emphasis.scss';

interface IProps {
  posts: IPost[];
}

const Emphasis: React.FC<IProps> = ({ posts }) => {
  const firstPost = posts.find((item, index) => index === 0);

  const [imageLoaded, setImageLoaded] = React.useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  function removeTags(text: string) {
    const match: RegExpMatchArray | null = text.match(/<p[^>]*>(.*?)<\/p>/);

    if (match) {
      return match[1].replace(/<[^>]*>/g, '');
    }

    return text;
  }

  const ComponentFirstPost = () => {
    if (firstPost) {
      return (
        <a href={`/blog/${firstPost.url}`} className='no-decoration'>
          <div className='emphasis-container'>
            <div className='image-container'>
              <div className='image-container'>
                {!imageLoaded && <Skeleton width='100%' height='300px' />}
                <img
                  className={`image ${!imageLoaded ? 'none' : 'flex'}`}
                  src={`https://firebasestorage.googleapis.com/v0/b/erp-epsys.appspot.com/o/blog-images%2F${firstPost.image}?alt=media`}
                  onLoad={handleImageLoad}
                />
              </div>
            </div>
            <div className='description-container'>
              <div className='line-clamp'>
                <div className='title'>{firstPost.title}</div>
                <div className='date'>
                  Por {firstPost.author}, {format(firstPost.createdAt.toDate(), 'dd/LL/yyyy')}
                </div>
                <div className='description'>{removeTags(firstPost.article)}</div>
              </div>
            </div>
          </div>
        </a>
      );
    }
  };

  return <ComponentFirstPost />;
};

export default Emphasis;
