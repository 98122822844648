import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';

import { useLocation } from 'react-router-dom';

import { NavigatorContext } from 'contexts/navigator.context';

import { reveal } from 'utils/reveal';

import AboutUs from './aboutUs/aboutUs';
import Contact from './contact/contact';
import Courses from './courses/courses';
import Gallery from './gallery/gallery';
import Home from './home/home';
import LeadContact from './leadContact/leadContact';
import LeadMap from './leadMap/leadMap';
import { usePosts } from './posts/hooks/usePosts';
import Posts from './posts/posts';
import Testimony from './testimony/testimony';

import './institucional.scss';

const Institucional: React.FC = () => {
  const { setterNavigator, setterEnableAdminNav, setterEnableControlPannel } = React.useContext(NavigatorContext);

  const location = useLocation();

  const { posts } = usePosts();

  window.addEventListener('scroll', reveal);

  React.useEffect(() => {
    if (location.hash) {
      document.getElementById(location.hash.replace('#', ''))?.scrollIntoView({ behavior: 'auto' });
    }

    setterEnableAdminNav(false);
    setterEnableControlPannel(false);
    setterNavigator('ABSOLUTE');
  }, [location.hash, setterEnableAdminNav, setterEnableControlPannel, setterNavigator]);

  ReactGA.send({ hitType: 'pageview', page: '/' });
  ReactPixel.pageView();

  return (
    <div className='institucional'>
      <Home />
      <AboutUs />
      <Courses />
      <LeadMap />
      {posts.length !== 0 && <Posts posts={posts} />}
      <LeadContact />
      <Gallery />
      <Testimony />
      <Contact />
    </div>
  );
};

export default Institucional;
