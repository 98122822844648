import React from 'react';

import { Chips, ChipsProps } from 'primereact/chips';

import BasicIcon from 'components/icon/basicIcon/basicIcon';

import { YupTypesError } from 'types/yupTypes';

import { BasicInputErrors } from '../basicInputErrors/basicInputErrors';

import './basicChips.scss';

type IconPosition = 'LEFT' | 'RIGHT';

export interface BasicInputProps extends ChipsProps {
  iconName?: string;
  iconPosition?: IconPosition;
  iconFunction?: () => void;
  inputId?: string;
  mandatory?: boolean;
  label?: string;
  type?: 'email' | 'hidden' | 'number' | 'password' | 'text' | 'url';
  disabled?: boolean;
  customClassName?: string;
  floatLabel?: boolean;
  validationErrors?: YupTypesError[];
}
export const BasicChips: React.FC<BasicInputProps> = (props) => {
  const {
    iconName,
    iconPosition,
    iconFunction,
    inputId,
    mandatory,
    label,
    disabled,
    customClassName,
    floatLabel,
    validationErrors,
    ...inputProps
  } = props;

  return (
    <div className={`basic-chips ${customClassName ? customClassName + ' ' + props.className : props.className}`}>
      <span
        className={`${floatLabel ? 'p- float-label' : ''} ${
          iconPosition ? 'p-input-icon-' + iconPosition.toLowerCase() : ''
        }`}
      >
        {iconName && (
          <BasicIcon
            iconName={iconName}
            className={`icon ${iconFunction ? 'cursor' : ''}`}
            onClick={() => {
              iconFunction && iconFunction();
            }}
          />
        )}
        <Chips id={inputId} name={inputId} className={`field`} disabled={disabled} {...inputProps} />
        <label htmlFor={inputId}>{(label || '') + (mandatory ? ' *' : '')}</label>
      </span>
      {inputId && validationErrors && <BasicInputErrors inputId={inputId} validationErrors={validationErrors} />}
    </div>
  );
};
