import * as React from 'react';
import { collection, limit, onSnapshot, orderBy, query } from 'firebase/firestore';

import { firebaseFirestore } from 'services/firebase/authentication';

import { IPost } from 'types/post';

interface PostsHook {
  recentPosts: IPost[];
  pendingRecentPosts: boolean;
}

export function useRecentPosts(): PostsHook {
  const [recentPosts, setRecentPosts] = React.useState<IPost[]>([]);
  const [pendingRecentPosts, setPendingRecentPosts] = React.useState(true);

  React.useEffect(() => {
    setPendingRecentPosts(true);

    const unsubscribe = onSnapshot(
      query(
        collection(firebaseFirestore, 'ALL-code3c', '010500005', 'BWS-posts'),
        orderBy('createdAt', 'desc'),
        limit(4)
      ),
      (snapshot) => {
        const list: IPost[] = [];

        if (snapshot.empty) {
          setPendingRecentPosts(false);
          return;
        }

        snapshot.docs.forEach((item) => {
          list.push({ ...item.data(), id: item.id } as IPost);
        });

        setRecentPosts(list);
        setPendingRecentPosts(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  return { recentPosts, pendingRecentPosts };
}
