import * as React from 'react';

import { Navigate } from 'react-router-dom';

import { Auth } from 'contexts/auth.context';

interface PrivateRoute {
  element: JSX.Element;
  title: string;
  redirect?: string;
}

export const PrivateRoute: React.FC<PrivateRoute> = ({ redirect, element }) => {
  const { authGrant, user } = React.useContext(Auth);

  if (authGrant && user) {
    return element;
  }

  if (!authGrant && user) {
    return <Navigate to={redirect || '/admin'} />;
  }

  return <Navigate to={redirect || '/admin'} />;
};
