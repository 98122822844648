import * as React from 'react';

import { Dropdown, DropdownProps } from 'primereact/dropdown';

import 'styles/buttons.scss';
import './basicDropdown.scss';

interface BasicButtonProps extends DropdownProps {
  customClassName?: string;
  inputId?: string;
  label?: string;
}

export const BasicDropdown = (props: BasicButtonProps) => {
  const { customClassName, inputId, label, ...buttonProps } = props;

  return (
    <div className='basic-dropdown'>
      <span className='p-float-label'>
        <Dropdown
          className={`field ${customClassName ? customClassName + ' ' + props.className : props.className}`}
          {...buttonProps}
        />
        <label htmlFor={inputId}>{label}</label>
      </span>
    </div>
  );
};
