import React from 'react';

import { format } from 'date-fns';

import { Skeleton } from 'primereact/skeleton';

import { IPost } from 'types/post';

import './card.scss';

interface IProps {
  item: IPost;
}

const Card: React.FC<IProps> = ({ item }) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  function removeTags(text: string) {
    const match: RegExpMatchArray | null = text.match(/<p[^>]*>(.*?)<\/p>/);

    if (match) {
      return match[1].replace(/<[^>]*>/g, '');
    }

    return text;
  }

  const ComponentCard = () => {
    return (
      <a href={`/blog/${item.url}`} className='no-decoration'>
        <div className='posts-content'>
          <div className='image-container'>
            {!imageLoaded && <Skeleton width='100%' height='300px' />}
            <img
              className='image'
              src={`https://firebasestorage.googleapis.com/v0/b/erp-epsys.appspot.com/o/blog-images%2F${item.image}?alt=media`}
              onLoad={handleImageLoad}
              style={{ display: !imageLoaded ? 'none' : 'flex' }}
            />
          </div>
          <div className='description-container'>
            <div className='line-clamp'>
              <div className='title'>{item.title}</div>
              <div className='date'>
                Por {item.author}, {format(item.createdAt.toDate(), 'dd/LL/yyyy')}
              </div>
              <div className='description'>{removeTags(item.article)}</div>
            </div>
          </div>
        </div>
      </a>
    );
  };

  return <ComponentCard />;
};

export default Card;
