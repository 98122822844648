import React from 'react';

import { NavigatorContext } from 'contexts/navigator.context';

import { BasicButton } from 'components/buttons/basicButton/basicButton';
import { BasicInput } from 'components/inputs/basicInput/basicInput';

import { useMails } from 'hooks/useMails';
import { useSearchOptions } from 'hooks/useOptions';

import PannelContactsDataTable from './components/pannelContactsDataTable';
import PannelContactsExportData from './components/pannelContactsExportData';

import './pannelContacts.scss';

const PannelContacts: React.FC = () => {
  const { setterNavigator, setterEnableAdminNav, setterEnableControlPannel, setterEnableHeader } =
    React.useContext(NavigatorContext);

  const [searchMail, setSearchMail] = React.useState<string>('');
  const [internalInputValue, setInternalInputValue] = React.useState('');

  const { mails, pendingMails, loadMore } = useMails(searchMail);
  const { options, pendingOptions } = useSearchOptions('email');

  const [showHideExportDialog, setShowHideExportDialog] = React.useState<boolean>(false);

  React.useEffect(() => {
    setterNavigator('RELATIVE');
    setterEnableAdminNav(true);
    setterEnableControlPannel(true);
    setterEnableHeader(true);
  }, [setterEnableAdminNav, setterEnableControlPannel, setterEnableHeader, setterNavigator]);

  const ComponentMorePosts = () => {
    if (mails.length !== options?.count && mails.length > 0 && searchMail === '') {
      return (
        <div className='pannel-contacts-load-more'>
          <BasicButton
            className='button-load-more'
            label='Carregar mais'
            onClick={() => loadMore()}
            disabled={pendingMails || pendingOptions}
          />
        </div>
      );
    }
  };

  return (
    <div className='pannel-contacts'>
      <PannelContactsExportData
        visible={showHideExportDialog}
        setVisible={setShowHideExportDialog}
        countMails={options?.count}
      />
      <div className='pannel-contacts-header'>
        <BasicButton
          label='Exportar'
          iconName='FaFileExport'
          customClassName='pannel-contacts-export-button'
          onClick={() => setShowHideExportDialog(true)}
        />
        <div className='pannel-contacts-searchbar'>
          <BasicInput
            placeholder='Pesquisar nome'
            type='text'
            iconName='FaMagnifyingGlass'
            iconPosition='LEFT'
            onChange={(e) => setInternalInputValue(e.target.value)}
            customClassName='pannel-contacts-searchbar-input'
            value={internalInputValue}
          />
          <BasicButton
            className='pannel-contacts-search'
            label='Pesquisar'
            onClick={() => setSearchMail(internalInputValue)}
          />
        </div>
      </div>
      <PannelContactsDataTable mails={mails} pendingMails={pendingMails} />
      <ComponentMorePosts />
    </div>
  );
};

export default PannelContacts;
