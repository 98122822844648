import React from 'react';
import imageCompression from 'browser-image-compression';

export function previewImage(file: File, setImageSRC: React.Dispatch<React.SetStateAction<string>>) {
  const options = {
    maxSizeMB: 3,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  imageCompression(file, options).then(async (result) => {
    await imageCompression
      .getDataUrlFromFile(result)
      .then((base64) => {
        setImageSRC(base64);
      })
      .catch(() => console.log('Limite Atingido'));
  });
}

export const headerQuill = () => {
  return (
    <React.Fragment>
      <span className='ql-formats'>
        <select className='ql-font' aria-label='Font' defaultValue='sans-serif'>
          <option value='sans-serif' />
          <option value='serif' />
          <option value='monospace' />
        </select>
        <select className='ql-size' defaultValue='normal'>
          <option value='small'>Pequeno</option>
          <option value='normal'>Normal</option>
          <option value='large'>Grande</option>
          <option value='huge'>Enorme</option>
        </select>
      </span>
      <span className='ql-formats'>
        <select className='ql-background' aria-label='Background Color' defaultValue='#000000'>
          <option value='#000000' />
          <option value='#e60000' />
          <option value='#ff9900' />
          <option value='#ffff00' />
          <option value='#008a00' />
          <option value='#0066cc' />
          <option value='#9933ff' />
          <option value='#facccc' />
          <option value='#ffebcc' />
          <option value='#ffffcc' />
          <option value='#cce8cc' />
          <option value='#cce0f5' />
          <option value='#ebd6ff' />
          <option value='#bbbbbb' />
          <option value='#f06666' />
          <option value='#ffc266' />
          <option value='#ffff66' />
          <option value='#66b966' />
          <option value='#66a3e0' />
          <option value='#c285ff' />
          <option value='#888888' />
          <option value='#a10000' />
          <option value='#b26b00' />
          <option value='#b2b200' />
          <option value='#006100' />
          <option value='#0047b2' />
          <option value='#6b24b2' />
          <option value='#444444' />
          <option value='#5c0000' />
          <option value='#663d00' />
          <option value='#666600' />
          <option value='#003700' />
          <option value='#002966' />
          <option value='#3d1466' />
        </select>
        <select className='ql-color' aria-label='Color' defaultValue='#000000'>
          <option value='#000000' />
          <option value='#e60000' />
          <option value='#ff9900' />
          <option value='#ffff00' />
          <option value='#008a00' />
          <option value='#0066cc' />
          <option value='#9933ff' />
          <option value='#facccc' />
          <option value='#ffebcc' />
          <option value='#ffffcc' />
          <option value='#cce8cc' />
          <option value='#cce0f5' />
          <option value='#ebd6ff' />
          <option value='#bbbbbb' />
          <option value='#f06666' />
          <option value='#ffc266' />
          <option value='#ffff66' />
          <option value='#66b966' />
          <option value='#66a3e0' />
          <option value='#c285ff' />
          <option value='#888888' />
          <option value='#a10000' />
          <option value='#b26b00' />
          <option value='#b2b200' />
          <option value='#006100' />
          <option value='#0047b2' />
          <option value='#6b24b2' />
          <option value='#444444' />
          <option value='#5c0000' />
          <option value='#663d00' />
          <option value='#666600' />
          <option value='#003700' />
          <option value='#002966' />
          <option value='#3d1466' />
        </select>
      </span>
      <span className='ql-formats'>
        <button type='button' className='ql-bold' aria-label='Bold' />
        <button type='button' className='ql-italic' aria-label='Italic' />
        <button type='button' className='ql-underline' aria-label='Underline' />
        <select className='ql-align' defaultValue=''>
          <option value='' />
          <option value='center' />
          <option value='right' />
          <option value='justify' />
        </select>
      </span>
      <span className='ql-formats'>
        <button type='button' className='ql-link' aria-label='Link' />
      </span>
      <span className='ql-formats'>
        <button type='button' className='ql-clean' aria-label='Remove Styles' />
      </span>
    </React.Fragment>
  );
};
