import React from 'react';

import { YupTypesError } from 'types/yupTypes';

import './basicInputErrors.scss';

export interface BasicInputErrorsProps {
  inputId: string;
  validationErrors: YupTypesError[];
}
export const BasicInputErrors = (props: BasicInputErrorsProps) => {
  const { inputId, validationErrors } = props;
  return (
    <div className='basic-input-errors'>
      {validationErrors.map((err, index) => {
        if (err.path === inputId) {
          return <p key={String(index)}>{err.errors}</p>;
        }
        return null;
      })}
    </div>
  );
};
