import React from 'react';

import { BasicButton } from 'components/buttons/basicButton/basicButton';

import './leadContact.scss';

const LeadContact: React.FC = () => {
  return (
    <div id='lead-contact' className='container-contact'>
      <div className='image'>
        <div className='content reveal'>
          <div className='title'>
            Ficou com <br />
            alguma Duvida?
          </div>
          <div className='subtitle'>Entre em Contato Conosco</div>
          <BasicButton
            label='ENTRAR EM CONTATO'
            severity='info'
            customClassName='button'
            onClick={() => {
              window.open(
                window.innerWidth < 1200
                  ? 'https://api.whatsapp.com/send?phone=5577999136775&text=Ol%C3%A1! Vim pelo site e tenho algumas dúvidas. Gostaria de obter mais informações. Você pode me ajudar com isso?'
                  : 'https://web.whatsapp.com/send?phone=5577999136775&amp;text=Olá! Vim pelo site e tenho algumas dúvidas. Gostaria de obter mais informações. Você pode me ajudar com isso?'
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default LeadContact;
