import * as React from 'react';
import { sendMail } from 'controller/contact.controller';

import { format } from 'date-fns';

import { ContactSchema, newsletterSchema } from 'pages/institucional/contact/contact.validations';

import { BasicButton } from 'components/buttons/basicButton/basicButton';
import BasicIcon from 'components/icon/basicIcon/basicIcon';
import { BasicInput } from 'components/inputs/basicInput/basicInput';

import { IMail } from 'types/mail';
import { YupTypesError } from 'types/yupTypes';

import { newsletterEmailTemplate } from './components/newsletterEmailTemplate';

import './footer.scss';

const Footer: React.FC = () => {
  const [email, setEmail] = React.useState<string>('');

  const [sended, setSended] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [validationErrors, setValidationErrors] = React.useState<YupTypesError[]>([]);

  const handleSendEmailToNewsletter = (): void => {
    setLoading(true);

    const contactValidation: Omit<ContactSchema, 'name' | 'phone' | 'course' | 'description' | 'acceptedNews'> = {
      email: email,
    };

    newsletterSchema
      .validate(contactValidation, { abortEarly: false })
      .then(async () => {
        const emailParams: Partial<IMail> = {
          to: ['epsys.tech@gmail.com'],
          code3c_id: '010500005',
          message: {
            subject: 'Nova mensagem do site Instituto Pinheiro',
            text: 'Um novo usuário inscreveu-se em sua newsletter!',
            html: newsletterEmailTemplate(email),
          },
          contact: {
            email: email,
            acceptedNews: true,
          },
        };
        const result = await sendMail(emailParams);
        if (result) {
          setEmail('');
          setLoading(false);
          setSended(true);
        }
      })
      .catch((err: YupTypesError) => {
        if (err) {
          setValidationErrors(err.inner);
        } else {
          setValidationErrors([]);
        }
        setLoading(false);
      });
  };

  return (
    <div id='footer' className='container'>
      <div className='footer-newsletter'>
        <h1 className='footer-newsletter-title'>Participe da nossa newsletter</h1>
        <div className='footer-newsletter-form'>
          <BasicInput
            placeholder='Coloque seu Email'
            iconName='FaEnvelope'
            iconPosition='LEFT'
            customClassName='footer-newsletter-form-input'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            inputId='email'
            validationErrors={validationErrors}
          />
          <BasicButton
            label='Enviar Email'
            severity='warning'
            customClassName='footer-newsletter-form-button'
            onClick={handleSendEmailToNewsletter}
            disabled={loading}
          />
        </div>
        {sended && <span className='contact-success-message'>Obrigado por se inscrever na nossa Newsletter!</span>}
        <div className='footer-newsletter-line' />
      </div>
      <div className='background-image'>
        <div className='content'>
          <div className='navigation-footer'>
            <div className='menu-footer'>
              <div className='menu-footer-title'>Menu</div>
              <div className='menu-footer-links'>
                <a href='/#'>Home</a>
                <a href='/#sobre-nos'>Sobre nós</a>
                <a href='/#cursos'>Cursos</a>
                <a href='/#contato'>Contatos</a>
                <a href='/blog'>Blog</a>
              </div>
            </div>
            <div className='contact-footer'>
              <div className='contact-footer-title'>Redes Sociais</div>
              <div className='contact-footer-descriptions'>
                <div className='contact-footer-social-media'>
                  <BasicIcon
                    iconName='FaFacebook'
                    onClick={() => {
                      window.open('https://www.facebook.com/CruzeiroVirtual/?locale=pt_BR');
                    }}
                  />
                  <BasicIcon
                    iconName='FaYoutube'
                    onClick={() => {
                      window.open('https://www.youtube.com/channel/UCfbZGyNRpOIuiksvLA8022w');
                    }}
                  />
                </div>
                <div>
                  <small>barreiras.cruzeirodosul@gmail.com</small>
                </div>
                <a
                  className='qlwapp-toggle contact-footer-whatsapp'
                  data-action='open'
                  data-phone='5577999136775'
                  data-message='Olá! Vim pelo site e tenho algumas dúvidas. Gostaria de obter mais informações. Você pode me ajudar com isso?'
                  role='button'
                  target='_blank'
                  rel='noreferrer'
                  href={
                    window.innerWidth < 1200
                      ? 'https://api.whatsapp.com/send?phone=5577999136775&text=Ol%C3%A1! Vim pelo site e tenho algumas dúvidas. Gostaria de obter mais informações. Você pode me ajudar com isso?'
                      : 'https://web.whatsapp.com/send?phone=5577999136775&amp;text=Olá! Vim pelo site e tenho algumas dúvidas. Gostaria de obter mais informações. Você pode me ajudar com isso?'
                  }
                >
                  <span>
                    <BasicIcon iconName='FaWhatsapp' />
                  </span>
                  &nbsp;(77) 99913-6775
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='container-footer'>
          <div className='powered-footer'>
            <div className='programming-footer'>
              Desenvolvido por&nbsp;
              <a target='_blank' rel='noreferrer' href='http://epsys.io/'>
                EPSYS
              </a>
            </div>
            <div className='rights-footer'>
              <div>Todos os direitos reservados | 2017 - {format(new Date(), 'yyyy')} </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
