import * as React from 'react';
import { collection, limit, onSnapshot, orderBy, query } from 'firebase/firestore';

import { firebaseFirestore } from 'services/firebase/authentication';

import { IPost } from 'types/post';

interface PostsHook {
  posts: IPost[];
  pendingPosts: boolean;
}

export function usePosts(): PostsHook {
  const [posts, setPosts] = React.useState<IPost[]>([]);
  const [pendingPosts, setPendingPosts] = React.useState(false);

  React.useEffect(() => {
    setPendingPosts(true);

    const unsubscribe = onSnapshot(
      query(collection(firebaseFirestore, 'ALL-code3c', '010500005', 'BWS-posts'), limit(3), orderBy('updatedAt')),
      (snapshot) => {
        const list: IPost[] = [];

        if (snapshot.empty) {
          setPendingPosts(false);
          return;
        }

        snapshot.docs.forEach((item) => {
          list.push({ ...item.data(), id: item.id } as IPost);
        });

        setPosts(list);
        setPendingPosts(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  return { posts, pendingPosts };
}
