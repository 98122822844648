import adm from 'assets/images/courses/administracao_pdp.png';
import ani from 'assets/images/courses/animacao-digital.jpg';
import bio from 'assets/images/courses/biomedicina-estetica.png';
import des from 'assets/images/courses/design-thinking.jpg';
import edu from 'assets/images/courses/educacao-infantil-conhecimento-aprendizagem-e-desenvolvimento.png';
import emp from 'assets/images/courses/empreendedorismo-da-ideia-ao-plano-de-negocios.jpg';
import enf from 'assets/images/courses/enfermagem_estetica.png';
import eng from 'assets/images/courses/engenharia-de-seguranca-do-trabalho.png';
import far from 'assets/images/courses/farmacia.jpg';
import rh from 'assets/images/courses/gestao_de_recursos_humanos_pdp.png';
import gesm from 'assets/images/courses/gestao-avancada-de-marketing.jpg';
import gesp from 'assets/images/courses/gestao-de-pessoas-e-negociacao.jpg';
import ges from 'assets/images/courses/gestao-escolar-6-meses.png';
import lid from 'assets/images/courses/lideranca.jpg';
import mar from 'assets/images/courses/marketing-digital.png';
import nut from 'assets/images/courses/nutricao.png';
import ped from 'assets/images/courses/pedagogia_pdp.png';
import logoMust from 'assets/images/logo_must.png';

const courses = [
  {
    title: 'Cursos para Graduação',
    cards: [
      {
        title: 'ADMINISTRAÇÃO',
        description:
          'O administrador é um profissional versátil, presente em diversas áreas de atuação. Pode ocupar posições de analista (nos níveis júnior, pleno, sênior) e as de supervisão e gestão (gerência e diretor). Mas também pode ser assessor ou trabalhar por conta própria como consultor. Assim que formado, deve solicitar o Registro Profissional no Conselho Regional de Administração (CRA) de seu estado.',
        image: adm,
        logo: '',
      },
      {
        title: 'CST EM GESTÃO DE RECURSOS HUMANOS',
        description:
          'Lidar com pessoas no ambiente de trabalho, em geral, é um desafio para o gestor. Como as pessoas são o principal ativo de uma organização, é importante saber como potencializar seus pontos fortes e reduzir suas fragilidades.',
        image: rh,
        logo: '',
      },
      {
        title: 'FARMÁCIA (BACHARELADO)',
        description:
          'Você é o tipo de pessoa que quer saber mais sobre os componentes químicos de cada medicamento, como funcionam as indústrias farmacêuticas ou como as pesquisas no campo da saúde são desenvolvidas? O curso de Farmácia pode ser a melhor opção para você!',
        image: far,
        logo: '',
      },
      {
        title: 'PEDAGOGIA (LICENCIATURA)',
        description:
          'O profissional formado em Pedagogia é especialmente habilitado a planejar, executar e avaliar qualquer processo de aprendizagem. Ele sabe como ninguém organizar projetos, estabelecer objetivos educacionais e definir os passos para que sejam alcançados. E muitas ações bacanas e relevantes à sociedade são coordenadas por esse profissional.',
        image: ped,
        logo: '',
      },
      {
        title: 'Nutrição',
        description:
          'Você é aquilo que você come, já dizia o dito popular. Sim, porque sem a energia fornecida pelos alimentos, o corpo não funciona nem se desenvolve. E no curso de Nutrição da Cruzeiro do Sul Virtual, você vai poder comprovar que a ingestão equilibrada de nutrientes garante disposição e boa saúde!',
        image: nut,
        logo: '',
      },
    ],
  },
  {
    title: 'Cursos para Pós Graduação lato sensu',
    cards: [
      {
        title: 'EDUCAÇÃO INFANTIL',
        description:
          'A valorização dos professores é uma tendência no atual cenário e atuar com a Educação Infantil é exercer um papel importantíssimo no desenvolvimento psicomotor, cognitivo e socioemocional dos pequenos.',
        image: edu,
        logo: '',
      },
      {
        title: 'ENFERMAGEM ESTÉTICA',
        description:
          'Essa é uma das áreas que atualmente vem despertando o interesse de profissionais de enfermagem, devido à alta demanda do mercado. O curso de Pós-graduação em Enfermagem Estética busca ampliar essas as possibilidades, para que profissionais da área possam atuar em consonância com as novas perspectivas das metodologias, tecnologias e práticas de mercado.',
        image: enf,
        logo: '',
      },
      {
        title: 'ENGENHARIA DE SEGURANÇA DO TRABALHO',
        description:
          'No contexto atual, em que o Brasil ocupa um dos primeiros lugares no ranking mundial de acidentes de trabalho, surge a necessidade de formar Engenheiros de Segurança do Trabalho, capazes de promover um ambiente empregatício saudável e prevenir acidentes.',
        image: eng,
        logo: '',
      },
      {
        title: 'GESTÃO ESCOLAR',
        description:
          'O curso de Pós-graduação em Gestão Escolar - EAD 100% on-line se propõe a desenvolver o Gestor Escolar inserido na concepção de gestão democrática. Ter uma visão inovadora e empreendedora são focos das discussões desenvolvidas no nosso curso.',
        image: ges,
        logo: '',
      },
      {
        title: 'MARKETING DIGITAL E TRANSFORMAÇÃO DIGITAL',
        description:
          'Em um mundo cada vez mais digital, o curso de Marketing Digital e Transformação Empresarial traz uma proposta inovadora para impulsionar sua carreira e prepará-lo para os desafios do mercado atual.',
        image: mar,
        logo: '',
      },
      {
        title: 'Biomedicina Estética',
        description:
          'Se você busca aprimorar seus conhecimentos em procedimentos estéticos minimamente invasivos e atuar em um mercado em constante expansão, o curso de Biomedicina Estética da Cruzeiro do Sul Virtual é a escolha certa para você.',
        image: bio,
        logo: '',
      },
    ],
  },
  {
    title: 'Cursos para Pós Graduação stricto sensu',
    cards: [
      {
        title: 'GESTÃO DE CUIDADOS DA SAÚDE',
        description:
          'O programa de Mestrado em Gestão de Cuidados da Saúde da MUST University foi projetado para fornecer aos profissionais de saúde o conhecimento, os conjuntos de habilidades e as habilidades necessárias para serem administradores e gerentes de saúde eficazes.',
        image:
          'https://mustedu.com/wp-content/uploads/elementor/thumbs/img-master-01-pmayaktq7plnmm0eovo449pvpweb19gv8rnlwckhq8.jpg',
        logo: logoMust,
      },
      {
        title: 'TECNOLOGIAS EMERGENTES EM EDUCAÇÃO',
        description:
          'O curso de Tecnologia emergentes em educação ensina a empregar recursos tecnológicos como ferramenta para aprimorar o ensino. Nossos alunos aprendem a promover o desenvolvimento sócio-educativo e melhor acesso à informação com uso dos recursos mais modernos da atualidade.',
        image:
          'https://mustedu.com/wp-content/uploads/elementor/thumbs/img-master-03-pmayaktq7plnmm0eovo449pvpweb19gv8rnlwckhq8.jpg',
        logo: logoMust,
      },
      {
        title: 'NEGÓCIOS INTERNACIONAIS',
        description:
          'O programa de Mestrado em Negócios Internacionais da MUST University foi desenvolvido para preparar os alunos para oportunidades em indústrias e organizações multinacionais. Os alunos desenvolverão habilidades de liderança e uma compreensão abrangente de questões de negócios culturais, regulamentações internacionais e planejamento estratégico necessários para ter sucesso no mercado global dinâmico de hoje.',
        image:
          'https://mustedu.com/wp-content/uploads/elementor/thumbs/img-master-02-pmayaktq7plnmm0eovo449pvpweb19gv8rnlwckhq8.jpg',
        logo: logoMust,
      },
      {
        title: 'ADMINISTRAÇÃO DE EMPRESAS',
        description:
          'Este programa foi projetado para preparar os alunos para o campo competitivo de negócios. Como parte de sua educação, eles serão expostos a uma miríade de material educativo que inclui estudos de caso e uso estratégico dos recursos disponíveis. ',
        image:
          'https://mustedu.com/wp-content/uploads/elementor/thumbs/img-master-04b-pnagwrqj97gpp2l7p6lqq7lx31fubx51h2d2al57ls.jpg',
        logo: logoMust,
      },
      {
        title: 'MARKETING DIGITAL',
        description:
          'Empreender na Internet, uma tendência que virou realidade. Essa força do ambiente digital passou a fazer parte do dia a dia dos consumidores, principalmente, com a chegada da pandemia. Aulas, compras, consultas, treinos, ou seja, tudo o que é consumido digitalmente precisa de um conjunto de estratégias voltado para a promoção da marca.',
        image:
          'https://mustedu.com/wp-content/uploads/elementor/thumbs/WhatsApp-Image-2021-11-04-at-8.06.12-PM-pn9q8gwf1ivuerha6l63v1ft0kn3g1t7s0lwqqzqgw.jpeg',
        logo: logoMust,
      },
      {
        title: 'DESENVOLVIMENTO DE NEGÓCIOS E INOVAÇÃO',
        description:
          'O programa de Mestrado em Desenvolvimento de Negócios e Inovação da MUST University foi projetado para preparar os alunos para serem empreendedores com uma forte perspectiva inovadora. Como parte de sua educação, serão expostos a diferentes modelos de negócios, como as tecnologias estão mudando os negócios, as etapas e a mentalidade certa que usarão para criar um novo negócio nos dias atuais e no futuro.',
        image:
          'https://mustedu.com/wp-content/uploads/elementor/thumbs/WhatsApp-Image-2021-09-15-at-1.25.21-PM-2-pn9qklzdbhi6dfueac3mkh558qsrvb0eg3yowezo3k.jpeg',
        logo: logoMust,
      },
      {
        title: 'PSICOLOGIA ORGANIZACIONAL',
        description:
          'A Pós-graduação em Psicologia Organizacional especializa profissionais para lidar com precisão em pessoas, seus problemas e conflitos dentro de uma instituição. Para isso, é necessário aperfeiçoar conhecimentos específicos de Psicologia e gestão de pessoas.',
        image:
          'https://mustedu.com/wp-content/uploads/elementor/thumbs/shutterstock_1851034798-pn9pswolw9ky76311v0gkyt2yxcc0g16yzsipu2dhc.jpg',
        logo: logoMust,
      },
      {
        title: 'ESTUDOS JURÍDICOS',
        description:
          'O programa de Mestrado em Estudos Jurídicos com ênfase em Direito Internacional foi projetado para fornecer uma base sólida em doutrinas e conceitos jurídicos enquanto desenvolve e refina a capacidade do estudante de identificar e analisar questões legais.',
        image:
          'https://mustedu.com/wp-content/uploads/elementor/thumbs/shutterstock_1350699089-pn9pxuc3ryc55gwz8hsy67z78r0pf8mgnf3af4qytc.jpg',
        logo: logoMust,
      },
    ],
  },
  {
    title: 'Cursos livres',
    cards: [
      {
        title: 'Animação Digital',
        description:
          'A Animação Digital é uma das grandes áreas que envolvem a produção de Jogos Digitais. Saber animar um personagem ou uma cena em duas e/ou três dimensões é uma competência que todo desenvolvedor de jogos deve ter, quer seja um desenvolvedor independente ou parte da equipe de um grande estúdio.',
        image: ani,
        logo: '',
      },
      {
        title: 'Design Thinking',
        description:
          'O design thinking é uma metodologia de solução de problemas complexos centrada no humano, muito utilizada para processos de inovação em produtos ou serviços e baseada em três principais pilares: empatia, colaboração e experimentação.',
        image: des,
        logo: '',
      },
      {
        title: 'Empreendedorismo: da ideia ao plano de negócios',
        description:
          'O curso de Empreendedorismo: da Ideia ao Plano de Negócios propõe subsídios para que você compreenda de que forma se dá a atividade empreendedora transcendendo a ideia para o plano de negócios. Nele, você verá também os trâmites necessários à constituição de uma Empresa a fim de atuar de forma ética e responsável na consolidação de um negócio.',
        image: emp,
        logo: '',
      },
      {
        title: 'Gestão avançada de marketing',
        description:
          'O curso de Gestão Avançada de Marketing aborda o conceito, classificação e ciclo de vida do produto (CVP); marcas e compostos versus linhas de produtos; métodos utilizados pelas empresas na obtenção de ideias para o desenvolvimento de novas mercadorias e suas respectivas etapas do processo.',
        image: gesm,
        logo: '',
      },
      {
        title: 'Gestão de pessoas e negociação',
        description:
          'O curso Gestão de Pessoas e Negociação irá oferecer-lhe contato com as discussões mais recentes pertinentes às relações empresariais, além de proporcionar-lhe momentos de leitura (textual e audiovisual) e reflexão sobre a gestão de pessoas, por meio da compreensão de conceitos como motivação, liderança, trabalho em equipe, recrutamento, seleção, entre outros assuntos importantes na área organizacional.',
        image: gesp,
        logo: '',
      },
      {
        title: 'Liderança',
        description:
          'Este curso de Liderança pretende fornecer as ferramentas para aumentar sua autoconsciência, que o ajudará a saber quando algo é autêntico ou não; auxiliar a identificar os momentos em que outras pessoas o ajudaram a adquirir autoconsciência e quando você perdeu as oportunidades de fazê-lo. Você também pode se tornar apto a construir o próprio conjunto de ferramentas e, com elas, desenvolver a capacidade de aceitar e regular a si mesmo.',
        image: lid,
        logo: '',
      },
    ],
  },
];

export { courses };
