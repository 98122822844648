import t1 from '../assets/testimony1.png';
import t2 from '../assets/testimony2.png';
import t3 from '../assets/testimony3.png';
import t4 from '../assets/testimony4.png';
import t5 from '../assets/testimony5.png';
import t6 from '../assets/testimony6.png';

interface Testimonies {
  author: string;
  authorPosition: string;
  authorImage: string;
  testimony: string;
}

// Minha jornada na universidade tem sido incrível até agora. O suporte acadêmico que recebi tem sido excepcional. Os professores estão sempre dispostos a ajudar e proporcionar um ambiente de aprendizado acolhedor.\nAs aulas interativas têm sido uma parte fundamental da minha experiência. Através de discussões envolventes, atividades práticas e tecnologia de ponta, tenho a oportunidade de aplicar o que aprendo de forma prática. Além disso, o aprendizado que obtive até agora tem sido enriquecedor. Cada disciplina contribui para o meu crescimento acadêmico e pessoal, preparando-me para os desafios futuros. Estou orgulhosa de fazer parte desta universidade que valoriza a excelência acadêmica e proporciona um ambiente de aprendizado de alta qualidade

const testimonies = [
  {
    author: 'Ana Clara do Nascimento',
    authorPosition: 'Curso Graduação: Administração',
    authorImage: t1,
    testimony: `Minha jornada na universidade tem sido incrível até
    agora. O suporte acadêmico que recebi tem sido
    excepcional. Os professores estão sempre dispostos a
    ajudar e proporcionar um ambiente de aprendizado
    acolhedor.
    As aulas interativas têm sido uma parte fundamental da
    minha experiência. Através de discussões envolventes,
    atividades práticas e tecnologia de ponta, tenho a
    oportunidade de aplicar o que aprendo de forma prática.
    Além disso, o aprendizado que obtive até agora tem sido
    enriquecedor. Cada disciplina contribui para o meu
    crescimento acadêmico e pessoal, preparando-me para
    os desafios futuros.
    Estou orgulhosa de fazer parte desta universidade que valoriza a excelência acadêmica e
    proporciona um ambiente de aprendizado de alta qualidade.`,
  },
  {
    author: 'Naran Souza',
    authorPosition: 'Curso de Graduação: Gestão de recursos humanos',
    authorImage: t2,
    testimony: `Me chamo Naran Souza conclui em 2021 o curso em
    gestão de recursos humanos na universidade Cruzeiro
    do Sul virtual polo BARREIRAS 😉 foi uma experiência
    única com a modalidade EAD e sem dúvidas tirei todo o
    preconceito que tinha com relação a essa modalidade
    rsrs sem falar que se fosse para fazer presencial não
    iria conseguir devido à correria do trabalho😊hoje super
    indico e já vou fazer uma pós-graduação para agregar
    mais valor ao meu currículo. Só gratidão a todos que
    contribuiu para essa conquista, o suporte acadêmico do
    polo foi fundamental para esse processo e aqui estou eu
    com meu nível superior e muito feliz😍.`,
  },
  {
    author: 'Marcus Souza',
    authorPosition: 'Curso de Pós-graduação: Marketing Digital e Transformação Empresarial',
    authorImage: t3,
    testimony: `Para permanecer relevante no mercado, é essencial me
    manter atualizado e este curso novo da Cruzeiro do Sul em
    parceria com a Google me chamou a atenção. Este curso não
    é apenas uma oportunidade de aprendizado, mas uma
    celebração do compromisso com o aprendizado continuado.
    Estou em busca novas habilidades para me tornar competitivo,
    atualizado e prosperar em um ambiente em constante
    mudança.`,
  },
  {
    author: 'Stéfane Nascimento',
    authorPosition: 'Curso: Tecnólogo em Gestão de Recursos Humanos',
    authorImage: t4,
    testimony: `É gratificante e satisfatório fazer parte da Cruzeiro do
    Sul Polo Barreiras - Ba, é a realização de um dos meus
    sonhos. Desde o início até aqui tenho recebido
    grandes instruções e aprendizado onde jamais poderia
    imaginar. Iniciei o curso achando que seria um ensino
    monótono, mas não; logo no 1º semestre até o
    momento sou orientada por uma equipe responsável,
    qualificada, comprometida...., e principalmente pontual
    nas soluções diante dos desafios da comunicação
    digital. Sou grata por todo aprendizado até aqui e por
    muitos que viram, entretanto, são muitos e rotineiros os
    desafios; mas a CRUZEIRO DO SUL vem
    surpreendendo da melhor forma. E sei que irei
    alcançar os meus objetivos.`,
  },
  {
    author: 'Larissa Dourado',
    authorPosition: 'Curso: Bacharelado em Matemática',
    authorImage: t5,
    testimony: `É com imenso prazer que faço parte do grupo de alunos da Cruzeiro do Sul Barreiras, a qual vem me capacitando cada dia mais para meu futuro.
    Desde o início senti firmeza e confiança nos profissionais que vem me instruindo e ajudando desde o início. Consultorias, dúvidas sempre esclarecidas e ajuda em tudo que tenho dificuldade, seja em aulas ou sistema.
    Só tenho a agradecer e confiar na Cruzeiro do Sul por todo esse trabalho incrível que sempre fazem.
    Sem dúvida alguma minha pós-graduação será aqui também!`,
  },
  {
    author: 'Gabrielly de Oliveira',
    authorPosition: 'Curso: Bacharelado em Publicidade e Propaganda',
    authorImage: t6,
    testimony: `Faço parte desta grande universidade, que acima de tudo possibilita a realização de grandes sonhos.
    Sou estudante da Cruzeiro do Sul Virtual Barreiras, cursando Bacharelado em Publicidade e Propaganda, e sou imensamente grato pelo apoio da equipe do polo.
    Quero expressar minha gratidão por todo o suporte que tenho recebido nesta jornada em busca do sucesso!`,
  },
];

export { type Testimonies, testimonies };
