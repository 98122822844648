import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { BasicButton } from 'components/buttons/basicButton/basicButton';
import BasicIcon from 'components/icon/basicIcon/basicIcon';

import './pannelMenu.scss';

type ControlPannelNav = 'Pannel' | 'Contacts';

interface PannelMenuItem {
  name: ControlPannelNav;
  itemSelected: ControlPannelNav;
  label: string;
  iconName: string;
  onClick?: () => void;
}

const PannelMenuItem: React.FC<PannelMenuItem> = ({ name, itemSelected, label, iconName, onClick }) => {
  return (
    <div className={`pannel-menu-item ${name === itemSelected ? 'active' : ''}`} onClick={onClick}>
      <BasicIcon iconName={iconName} />
      <div className='pannel-menu-item-label'>{label}</div>
      <div className='pannel-menu-item-selected-icon'>
        {name === itemSelected && <BasicIcon iconName='FaAngleRight' />}
      </div>
    </div>
  );
};

const PannelMenu: React.FC = () => {
  const history = useNavigate();
  const location = useLocation();

  const [controlPannelNav, setControlPannelNav] = React.useState<ControlPannelNav>('Pannel');

  const newPost = () => {
    history('/admin/blog/novo');
  };

  React.useEffect(() => {
    if (location.pathname.startsWith('/admin/painel') || location.pathname.startsWith('/admin/blog')) {
      setControlPannelNav('Pannel');
    } else if (location.pathname.startsWith('/admin/contatos')) {
      setControlPannelNav('Contacts');
    }
  }, [location]);

  return (
    <div className='pannel-menu'>
      <BasicButton
        label='Nova Postagem'
        severity='secondary'
        iconName='FaCirclePlus'
        customClassName='pannel-menu-new-post'
        onClick={newPost}
      />
      <div className='pannel-menu-area-line' />
      <div className='pannel-menu-list'>
        <PannelMenuItem
          name='Pannel'
          itemSelected={controlPannelNav}
          label='Postagens'
          iconName='FaRectangleList'
          onClick={() => history('/admin/painel')}
        />
        <PannelMenuItem
          name='Contacts'
          itemSelected={controlPannelNav}
          label='Contatos'
          iconName='FaAddressBook'
          onClick={() => history('/admin/contatos')}
        />
      </div>
    </div>
  );
};

export default PannelMenu;
