import React from 'react';
import { deleteContact } from 'controller/contact.controller';

import { format } from 'date-fns';

import { Column } from 'primereact/column';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { DataTable, DataTableExpandedRows, DataTableValueArray } from 'primereact/datatable';
import { useResizeListener } from 'primereact/hooks';

import { BasicButton } from 'components/buttons/basicButton/basicButton';
import BasicIcon from 'components/icon/basicIcon/basicIcon';

import { IMail } from 'types/mail';

const PannelContactsDataTable: React.FC<{ mails: IMail[]; pendingMails: boolean }> = ({ mails, pendingMails }) => {
  const [expandedRows, setExpandedRows] = React.useState<DataTableValueArray | DataTableExpandedRows | undefined>(
    undefined
  );
  const [windowSize, setWindowSize] = React.useState({ width: 0, height: 0 });

  const [bindWindowResizeListener, unbindWindowResizeListener] = useResizeListener({
    listener: (event) => {
      const window = event.currentTarget as Window;
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    },
  });

  React.useEffect(() => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  }, []);

  React.useEffect(() => {
    bindWindowResizeListener();

    return () => {
      unbindWindowResizeListener();
    };
  }, [bindWindowResizeListener, unbindWindowResizeListener]);

  const confirmDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    const message = (
      <div className='pannel-contacts-confirm-dialog-message'>
        <BasicIcon iconName='FaTriangleExclamation' />
        <div>
          <p>Voce tem certeza que deseja apagar esse contato?</p>
          <small>*Essa é uma ação irreversível*</small>
        </div>
      </div>
    );
    confirmPopup({
      target: event.currentTarget,
      message: message,
      acceptClassName: 'pannel-contacts-confirm-dialog-delete-button',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      className: 'pannel-contacts-confirm-dialog',
      accept: () => deleteContact(id),
    });
  };

  const renderAcceptedNews = (rowData: IMail) => {
    const acceptedNewsIcon = rowData.contact.acceptedNews ? 'FaRegCircleCheck' : 'FaRegCircle';
    const acceptedNewsColor = rowData.contact.acceptedNews ? 'accepted-news-checked' : 'accepted-news-unchecked ';
    return (
      <div className='accepted-news-position'>
        <BasicIcon iconName={acceptedNewsIcon} className={acceptedNewsColor} />
      </div>
    );
  };

  const renderDelete = (rowData: IMail) => {
    return (
      <div className='pannel-contacts-item-actions'>
        <BasicButton
          customClassName='pannel-contacts-item-actions-delete-button'
          iconName='FaTrash'
          onClick={(ev) => confirmDelete(ev, rowData.id)}
        />
      </div>
    );
  };

  const allowExpansion = (rowData: IMail) => {
    return !!rowData.contact.description;
  };

  const renderPhone = (rowData: IMail) => {
    const phone = rowData?.contact?.phone?.replace(/\D/g, '');
    if (phone && rowData.contact.phone && rowData.contact.phone.length > 9) {
      return phone.replace(/(\d{2})(\d)/, '($1) $2').replace(/(\d)(\d{4})$/, '$1-$2');
    }
    return phone;
  };

  const renderCreatedAt = (rowData: IMail) => {
    return format(rowData.createdAt.toDate(), 'dd/LL/yyyy - H:mm');
  };

  const rowExpansionTemplate = (rowData: IMail) => {
    return (
      <div className='pannel-contacts-table-description'>
        <h5>Mensagem:</h5>
        <div className='pannel-contacts-table-description-area'>
          <i>
            <b>&quot;</b>
            {rowData.contact.description}
            <b>&quot;</b>
          </i>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <ConfirmPopup />
      <DataTable
        rows={10}
        dataKey='id'
        value={mails}
        emptyMessage='Nenhum Contato'
        globalFilterFields={['contact.name', 'contact.email', 'contact.phone', 'contact.acceptedNews']}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        loading={pendingMails}
        // BREAK - Como ainda funciona deixarei aqui até segunda ordem. Economia de tempo.
        responsiveLayout='stack'
      >
        {windowSize.width >= 940 && <Column expander={allowExpansion} style={{ width: '5rem' }} />}
        <Column field='contact.name' header='Nome' style={{ minWidth: '12rem' }} />
        <Column field='contact.email' header='Email' style={{ minWidth: '12rem' }} />
        <Column field='contact.phone' header='Telefone' body={renderPhone} style={{ minWidth: '12rem' }} />
        <Column field='createdAt' header='Recebido' body={renderCreatedAt} style={{ minWidth: '12rem' }} />
        <Column
          dataType='boolean'
          header='Newsletter'
          field='contact.acceptedNews'
          align='center'
          body={renderAcceptedNews}
          style={{ minWidth: '6rem' }}
        />
        <Column header='Deletar' align='center' body={renderDelete} style={{ minWidth: '6rem' }} />
        {windowSize.width < 940 && <Column header='Ver Mensagem' expander={allowExpansion} style={{ width: '5rem' }} />}
      </DataTable>
    </React.Fragment>
  );
};

export default PannelContactsDataTable;
