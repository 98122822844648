import React from 'react';
import verticalLogo6 from 'assets/images/logo.png';
import { signInUser } from 'controller/userAuth';

import { Navigate } from 'react-router-dom';

import { Auth } from 'contexts/auth.context';
import { NavigatorContext } from 'contexts/navigator.context';
import { Toaster } from 'contexts/toast.context';

import { BasicButton } from 'components/buttons/basicButton/basicButton';
import { BasicInput } from 'components/inputs/basicInput/basicInput';
import { AuthLoading } from 'components/loadings/authLoading/authLoading';

import { parseFirebaseErrors } from 'services/firebase/errors';

import { YupTypesError } from 'types/yupTypes';

import { SignInSchema, signInSchema } from './signin.validations';

import './signin.scss';

const SigninLogo: React.FC<{ signinLogo: string; onClick?: () => void }> = ({ signinLogo, onClick }) => {
  return <img className='signin-area-login-logo' src={signinLogo} onClick={onClick} loading='lazy' />;
};

const Signin: React.FC = () => {
  const { setterEnableAdminNav, setterEnableControlPannel } = React.useContext(NavigatorContext);
  const { user } = React.useContext(Auth);
  const { toastError } = React.useContext(Toaster);

  const [email, setEmail] = React.useState<string>('');
  const [pass, setPass] = React.useState<string>('');
  const [seePass, setSeePass] = React.useState<boolean>(false);

  const [loading, setLoading] = React.useState(false);

  const [validationErrors, setValidationErrors] = React.useState<YupTypesError[]>([]);

  const hideShowPass = () => {
    setSeePass((state) => !state);
  };

  const typePass = React.useMemo(() => {
    if (seePass) {
      return 'text';
    }
    return 'password';
  }, [seePass]);

  const iconNamePass = React.useMemo(() => {
    if (seePass) {
      return 'FaEyeSlash';
    }
    return 'FaEye';
  }, [seePass]);

  const handleLogin = (): void => {
    setLoading(true);

    const signInValidation: SignInSchema = {
      email: email,
      password: pass,
    };

    signInSchema
      .validate(signInValidation, { abortEarly: false })
      .then(() => {
        signInUser(email, pass).catch((err) => {
          console.log(err);
          toastError && toastError(parseFirebaseErrors(err));
          setLoading(false);
        });
      })
      .catch((err: YupTypesError) => {
        if (err) {
          console.log(err.inner);
          setValidationErrors(err.inner);
          console.error(`Errors occurred on this request:\n${err.inner}`);
        } else {
          setValidationErrors([]);
        }
        setLoading(false);
      });
  };

  React.useEffect(() => {
    setterEnableAdminNav(true);
    setterEnableControlPannel(false);
  }, [setterEnableAdminNav, setterEnableControlPannel]);

  if (user) {
    return <Navigate to={'/admin/painel'} />;
  }

  return (
    <div className='signin'>
      <div className='signin-area'>
        <div className='signin-area-login'>
          {loading || user ? (
            <AuthLoading />
          ) : (
            <React.Fragment>
              <SigninLogo signinLogo={verticalLogo6} />
              <div className='signin-area-login-line' />
              <h3>Login</h3>
              <BasicInput
                placeholder='E-mail'
                type='email'
                iconName='FaUser'
                iconPosition='LEFT'
                onChange={(e) => setEmail(e.target.value)}
                inputId='email'
                validationErrors={validationErrors}
              />
              <BasicInput
                placeholder='Senha'
                type={typePass}
                iconName={iconNamePass}
                iconPosition='LEFT'
                iconFunction={hideShowPass}
                onChange={(e) => setPass(e.target.value)}
                inputId='password'
                validationErrors={validationErrors}
              />
              <BasicButton label='Entrar' customClassName='custom-button' severity='secondary' onClick={handleLogin} />
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default Signin;
