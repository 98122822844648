import * as yup from 'yup';
import { pt as portuguese } from 'yup-locale-pt';

yup.setLocale(portuguese);

interface SignInSchema {
  email: string;
  password: string;
}

const signInSchema = yup.object().shape({
  email: yup.string().email().label('Email').required('Você precisa inserir um email'),
  password: yup.string().label('Senha').required('Você precisa inserir uma senha'),
});

export { type SignInSchema, signInSchema };
