import React from 'react';
import { utils, writeFile } from 'xlsx';

import { format } from 'date-fns';

import { Dialog } from 'primereact/dialog';
import { usePrevious } from 'primereact/hooks';

import { Toaster } from 'contexts/toast.context';

import { BasicButton } from 'components/buttons/basicButton/basicButton';
import { BasicDropdown } from 'components/navigator/components/basicDropdown/basicDropdown';

import { useMails } from 'hooks/useMails';

import { parseFirebaseErrors } from 'services/firebase/errors';

import { IMail } from 'types/mail';

import '../pannelContacts.scss';

interface IExtension {
  name: string;
  code: string;
}

const formatSelect: Array<IExtension> = [
  { name: 'XLSX (Excel 2007 ou Superior)', code: 'XLSX' },
  { name: 'XLS (Excel 97 ~ 2004)', code: 'XLS' },
  { name: 'CSV (Valores separados por vírgula)', code: 'CSV' },
];

const formatIcon = (code: string) => {
  switch (code) {
    case 'XLS':
    case 'XLSX': {
      return 'FaFileExcel';
    }
    case 'CSV': {
      return 'FaFileCsv';
    }
    default: {
      return 'FaFileExcel';
    }
  }
};

interface PannelContactsExportDataProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  countMails: number | undefined;
}

const PannelContactsExportData: React.FC<PannelContactsExportDataProps> = ({ visible, setVisible, countMails }) => {
  const { toastSuccess } = React.useContext(Toaster);
  const { exportAvailable } = useMails('');

  const [availableContacts, setAvailableContacts] = React.useState<IMail[]>();
  const [exportFormat, setExportFormat] = React.useState<IExtension>({
    name: 'XLSX (Excel 2007 ou Superior)',
    code: 'XLSX',
  });

  const previousCountMails = usePrevious(countMails);

  React.useEffect(() => {
    if ((!availableContacts || previousCountMails !== countMails) && visible) {
      exportAvailable()
        .then((contacts) => {
          setAvailableContacts(contacts);
        })
        .catch((err) => {
          console.error(parseFirebaseErrors(err));
        });
    }
  }, [availableContacts, countMails, exportAvailable, previousCountMails, visible]);

  const exportFile = React.useCallback(() => {
    const exportBody = availableContacts?.map((mail: IMail) => {
      return {
        Nome: mail.contact.name,
        Email: mail.contact.email,
        Telefone: mail.contact.phone,
        Data: format(mail.createdAt.toDate(), 'dd/LL/yyyy - H:mm'),
      };
    });

    const exportHeader = ['Nome', 'Email', 'Telefone', 'Data'];

    if (exportBody) {
      const wb = utils.book_new();
      const ws = utils.json_to_sheet(exportBody, { header: exportHeader });
      utils.book_append_sheet(wb, ws, 'Contatos');
      writeFile(
        wb,
        `Froes&Calazans-Contatos-${format(new Date(), 'dd-LL-yyyy')}.${exportFormat.code.toLocaleLowerCase()}`
      );
      setVisible(false);
      toastSuccess('Contatos exportados!');
    }
  }, [availableContacts, exportFormat, setVisible, toastSuccess]);

  const footerContent = (
    <div>
      <BasicButton
        customClassName='pannel-contacts-export-button'
        label='Exportar'
        onClick={() => exportFile()}
        autoFocus
        iconName={formatIcon(exportFormat.code)}
        disabled={!availableContacts}
      />
    </div>
  );

  return (
    <Dialog
      header='Exportar Contatos'
      visible={visible}
      style={{ width: '30vw' }}
      onHide={() => setVisible(false)}
      className='pannel-contacts-export-dialog'
      footer={footerContent}
    >
      <div className='pannel-contacts-export-data'>
        <p>
          <span>{availableContacts?.length ?? '?'}</span> Contatos a serem exportados
          <br />
          <small>*Apenas com aceite de newsletter*</small>
        </p>

        <BasicDropdown
          options={formatSelect}
          value={exportFormat}
          onChange={(e) => {
            setExportFormat({ name: e.value.name, code: e.value.code });
          }}
          optionLabel='name'
          filterBy='name'
          placeholder='Selecione um Formato'
        />
      </div>
    </Dialog>
  );
};

export default PannelContactsExportData;
