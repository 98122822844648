import * as React from 'react';

import './youtubeEmbed.scss';

interface YoutubeEmbed {
  embedLinkId: string;
}

const YoutubeEmbed: React.FC<YoutubeEmbed> = ({ embedLinkId }) => (
  <div className='video-responsive'>
    <iframe
      src={`https://www.youtube.com/embed/${embedLinkId}`}
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      title='Embedded youtube'
    />
  </div>
);

export default YoutubeEmbed;
