import { collection, doc, getDoc, Timestamp, updateDoc, writeBatch } from 'firebase/firestore';

import { firebaseFirestore } from 'services/firebase/authentication';

import { IPost } from 'types/post';

interface IOptions {
  count: number;
  id: string;
}

export const addPost = async (data: Partial<IPost>) => {
  try {
    const docRef = doc(firebaseFirestore, 'ALL-code3c', '010500005', 'BWS-shard', 'posts');
    const docSnap = await getDoc(docRef);

    const count = { id: docSnap.id, ...docSnap.data() } as IOptions;

    const batch = writeBatch(firebaseFirestore);

    batch.update(docRef, {
      count: count.count + 1,
    } as Partial<IOptions>);

    const collectionRef = collection(firebaseFirestore, 'ALL-code3c', '010500005', 'BWS-posts');

    const docRef2 = doc(collectionRef);

    batch.set(docRef2, {
      ...data,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
    });

    await batch.commit();

    return true;
  } catch (error) {
    console.log('Erro ao estabelecer conexão com o banco de dados', error);

    return false;
  }
};

export const editPost = async (data: Partial<IPost>, id: string) => {
  try {
    await updateDoc(doc(firebaseFirestore, 'ALL-code3c', '010500005', 'BWS-posts', id), {
      ...data,
      updatedAt: Timestamp.now(),
    });

    return true;
  } catch (error) {
    console.log('Erro ao estabelecer conexão com o banco de dados', error);

    return false;
  }
};

export const deletePost = async (id: string) => {
  try {
    const docRef = doc(firebaseFirestore, 'ALL-code3c', '010500005', 'BWS-shard', 'posts');
    const docSnap = await getDoc(docRef);

    const count = { id: docSnap.id, ...docSnap.data() } as IOptions;

    const batch = writeBatch(firebaseFirestore);

    batch.update(docRef, {
      count: count.count - 1,
    } as Partial<IOptions>);

    const laRef = doc(firebaseFirestore, 'ALL-code3c', '010500005', 'BWS-posts', id);

    batch.delete(laRef);

    await batch.commit();

    return true;
  } catch (error) {
    console.log('Erro ao estabelecer conexão com o banco de dados', error);

    return false;
  }
};
