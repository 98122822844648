import React from 'react';
import logo from 'assets/images/logo.png';

import { FaBars, FaXmark } from 'react-icons/fa6';

import { NavigatorContext } from 'contexts/navigator.context';

import { BasicButton } from 'components/buttons/basicButton/basicButton';

import useScrollBlock from 'hooks/useScrollBlock';

import UserHeaderMenu from '../userHeaderMenu/userHeaderMenu';

import './header.scss';

const HeaderLogo: React.FC<{ menuLogo: string; onClick?: () => void }> = ({ menuLogo, onClick }) => {
  return (
    <a href={'/'} className='header-logo-link'>
      <img className='header-logo' src={menuLogo} onClick={onClick} loading='lazy' />
      <h2>Instituto Pinheiro</h2>
    </a>
  );
};

interface HeaderMenuProps {
  windowWidth: number;
  responsiveWidth: number;
  openedMenu: boolean;
  openCloseMenu: () => void;

  children: React.ReactNode;
}

const HeaderMenu: React.FC<HeaderMenuProps> = ({
  windowWidth,
  responsiveWidth,
  children,
  openedMenu,
  openCloseMenu,
}) => {
  if (windowWidth > responsiveWidth) {
    return <div className='header-menu-list'>{children}</div>;
  }

  return (
    <div className='header-responsive-menu'>
      {openedMenu && (
        <div className='header-responsive-menu-area'>
          <div className='header-responsive-menu-area-button'>
            <FaXmark className='header-responsive-menu-icon' onClick={openCloseMenu} />
          </div>
          <HeaderLogo menuLogo={logo} onClick={openCloseMenu} />
          <div className='header-responsive-menu-area-line' />
          {children}
        </div>
      )}
      <FaBars className='header-responsive-menu-icon' onClick={openCloseMenu} />
    </div>
  );
};

const HeaderMenuItem: React.FC<{ name: string; href: string; onClick: () => void }> = ({ name, href, onClick }) => {
  return (
    <li>
      <a href={href} onClick={onClick}>
        {name}
      </a>
    </li>
  );
};

const Header: React.FC = () => {
  const { navigatorMode, enabledAdminNav } = React.useContext(NavigatorContext);
  const { blockScroll, allowScroll } = useScrollBlock();

  const [openedMenu, setOpenedMenu] = React.useState(false);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  const responsiveWidth = 940;

  const openCloseMenu = () => {
    if (windowWidth < responsiveWidth) {
      setOpenedMenu((state) => !state);
    }
  };

  React.useEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
  }, []);

  React.useEffect(() => {
    if (openedMenu) {
      blockScroll();
    } else {
      allowScroll();
    }
  }, [allowScroll, blockScroll, openedMenu]);

  return (
    <div className={`header${navigatorMode === 'RELATIVE' ? ' header-mode-relative' : ''}`}>
      <HeaderLogo menuLogo={logo} />
      {enabledAdminNav ? (
        <UserHeaderMenu />
      ) : (
        <HeaderMenu
          windowWidth={windowWidth}
          responsiveWidth={responsiveWidth}
          openedMenu={openedMenu}
          openCloseMenu={openCloseMenu}
        >
          <ul style={{ display: 'flex', alignItems: 'center' }}>
            <HeaderMenuItem name='Home' href='/#' onClick={openCloseMenu} />
            <HeaderMenuItem name='Sobre nós' href='/#sobre-nos' onClick={openCloseMenu} />
            <HeaderMenuItem name='Cursos' href='/#cursos' onClick={openCloseMenu} />
            <HeaderMenuItem name='Contato' href='/#contato' onClick={openCloseMenu} />
            <HeaderMenuItem name='Blog' href='/blog' onClick={openCloseMenu} />
            <BasicButton
              label='Entrar em Contato'
              severity='success'
              customClassName='header-menu-list-button'
              onClick={() => {
                window.open(
                  window.innerWidth < 1200
                    ? 'https://api.whatsapp.com/send?phone=5577999136775&text=Ol%C3%A1! Vim pelo site e tenho algumas dúvidas. Gostaria de obter mais informações. Você pode me ajudar com isso?'
                    : 'https://web.whatsapp.com/send?phone=5577999136775&amp;text=Olá! Vim pelo site e tenho algumas dúvidas. Gostaria de obter mais informações. Você pode me ajudar com isso?'
                );
              }}
            />
          </ul>
        </HeaderMenu>
      )}
    </div>
  );
};

export default Header;
