import React from 'react';

import { Skeleton } from 'primereact/skeleton';

import { IPost } from 'types/post';

import './similarPosts.scss';

interface IProps {
  similarPosts: IPost[];
  documentId: string;
}

const SimilarPosts: React.FC<IProps> = ({ similarPosts }) => {
  return (
    <div className='posts-similar'>
      <div className='title-similar'>Posts Similares:</div>
      <div className='col-container'>
        {similarPosts
          .filter((item, index) => index < 3)
          .map((item, index) => {
            const [imageLoaded, setImageLoaded] = React.useState(false);

            const handleImageLoad = () => {
              setImageLoaded(true);
            };

            return (
              <a key={index} href={`/blog/${item.url}`} className='col no-decoration'>
                <div className='image-container-similar'>
                  {!imageLoaded && <Skeleton width='100%' height='180px' />}
                  <img
                    className='image-similar'
                    src={`https://firebasestorage.googleapis.com/v0/b/erp-epsys.appspot.com/o/blog-images%2F${item.image}?alt=media`}
                    onLoad={handleImageLoad}
                    style={{ display: !imageLoaded ? 'none' : 'flex' }}
                  />
                </div>
                <div className='description-container-similar'>
                  <div className='title-similar'>{item.title}</div>
                </div>
              </a>
            );
          })}
      </div>
    </div>
  );
};

export default SimilarPosts;
