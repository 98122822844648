import React from 'react';

import { useParams } from 'react-router-dom';

import { BasicChips } from 'components/inputs/basicChips/basicChips';

import { YupTypesError } from 'types/yupTypes';

import './tags.scss';

interface IProps {
  tags: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
  preview: boolean;
  validationErrors: YupTypesError[];
}

const Tags: React.FC<IProps> = ({ tags, preview, setTags, validationErrors }) => {
  const { viewId } = useParams();

  return (
    <div className='tags-container'>
      <div className='title-tags'>
        Tags:{' '}
        {!viewId && <span style={{ fontSize: '1rem' }}>Utilize a tecla &#34;Enter&#34; para separar as tags</span>}
      </div>
      {viewId || preview ? (
        <div className='card'>
          {tags?.map((item, key) => (
            <div key={key} className='tag'>
              {item}
            </div>
          ))}
        </div>
      ) : (
        <div className='card p-fluid'>
          <BasicChips
            value={tags}
            onChange={(e) => setTags(e.value as string[])}
            placeholder='Tags'
            validationErrors={validationErrors}
            inputId='tags'
          />
        </div>
      )}
    </div>
  );
};

export default Tags;
