import React from 'react';

import { Button, ButtonProps } from 'primereact/button';

import BasicIcon from 'components/icon/basicIcon/basicIcon';

import 'styles/buttons.scss';
import './basicButton.scss';

interface BasicButtonProps extends ButtonProps {
  customClassName?: string;
  iconName?: string;
}

export const BasicButton = (props: BasicButtonProps) => {
  const { customClassName, iconName, ...buttonProps } = props;

  return (
    <React.Fragment>
      <Button
        className={`basic-button ${iconName && !props.label ? 'p-button-icon-only' : ''} ${
          customClassName ? customClassName + ' ' + props.className : props.className
        }`}
        {...buttonProps}
      >
        {!buttonProps.loading && iconName && <BasicIcon iconName={iconName} />}
      </Button>
    </React.Fragment>
  );
};
