import React from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { BasicButton } from 'components/buttons/basicButton/basicButton';

import { ICourse } from 'types/listCourses';

import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import Card from '../card/card';

import './list.scss';
import 'swiper/css';

export interface BasicListProps {
  cards: ICourse[];
}

const List: React.FC<BasicListProps> = ({ cards }) => {
  return (
    <div className='list'>
      <div className='list-cards'>
        <Swiper
          modules={[Pagination, Navigation]}
          effect='coverflow'
          spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            849: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            850: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1250: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
          grabCursor
          navigation
        >
          {cards.map((course, index) => (
            <SwiperSlide key={index} tabIndex={index} style={{ width: '18rem' }}>
              <Card
                key={course.title + index}
                image={course.image}
                title={course.title}
                description={course.description}
                logo={course.logo}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className='list-button'>
        <BasicButton
          onClick={() =>
            window.open(
              'https://firebasestorage.googleapis.com/v0/b/psh-fca.appspot.com/o/docs-cruzeiro%2Fcursos%20cruzeiro.pdf?alt=media&token=f2099d28-80a2-4615-b9fc-871e39abba60&_gl=1*13cgaxy*_ga*MTE0MjA1MDgxMi4xNjg4MDYyMjQ2*_ga_CW55HF8NVT*MTY5ODc2MDk2My4xODYuMS4xNjk4NzY2MDY4LjE5LjAuMA..'
            )
          }
          label='Veja a nossa lista de cursos'
          severity='warning'
        />
      </div>
    </div>
  );
};

export default List;
