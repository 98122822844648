import * as React from 'react';
import { collection, onSnapshot, query, where } from 'firebase/firestore';

import { firebaseFirestore } from 'services/firebase/authentication';

import { IPost } from 'types/post';

interface PostsHook {
  singlePost: IPost | undefined;
  pendingSinglePost: boolean;
}

export function usePostSingle(id: string): PostsHook {
  const [singlePost, setSinglePost] = React.useState<IPost>();
  const [pendingSinglePost, setPendingSinglePost] = React.useState(true);

  React.useEffect(() => {
    setPendingSinglePost(true);

    const unsubscribe = onSnapshot(
      query(collection(firebaseFirestore, 'ALL-code3c', '010500005', 'BWS-posts'), where('url', '==', id)),
      (snapshot) => {
        const list: IPost[] = [];

        if (snapshot.empty) {
          setPendingSinglePost(false);
          return;
        }

        snapshot.docs.forEach((item) => {
          list.push({ ...item.data(), id: item.id } as IPost);
        });

        setSinglePost(list.find((item) => item.url === id));
        setPendingSinglePost(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [id]);

  return { singlePost, pendingSinglePost };
}
